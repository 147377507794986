import {
  rand,
  randBoolean,
  randNumber,
  randUuid,
  randWord
} from '@ngneat/falso';

import { enumValueFaker, makeArray, randAlphaString } from '@utils/faker-utils';

import {
  Device,
  DeviceModelParameters,
  DeviceModels,
  OverridableEnum,
  ProductType
} from '@omnis-pulse-types';

export class DeviceMockFactory {
  static createDevice(data?: Partial<Device>): Device {
    const words = randWord({ length: 2 });
    return { id: randUuid(), name: words.join(' '), ...data };
  }

  static createDevices(length = 3): Device[] {
    return makeArray(length, DeviceMockFactory.createDevice);
  }

  static createProductType(data?: Partial<ProductType>): ProductType {
    return {
      manufacturer: rand([
        'Siemens',
        'ABB',
        'Itron',
        'Schneider Electric',
        'Honeywell'
      ]),
      model: randAlphaString(40),
      identity: {
        module: randWord(),
        key: randWord()
      },
      ...data
    };
  }

  static createProductTypes(length = 3): ProductType[] {
    return makeArray(length, DeviceMockFactory.createProductType);
  }

  static createDeviceModel(
    data?: Partial<DeviceModels>,
    numberOfVariants = 2,
    numberOfExtraParameters = 0
  ) {
    return {
      variants: [
        ...makeArray(
          numberOfVariants,
          DeviceMockFactory.createDeviceModelVariant
        ),
        ...(data?.variants || [])
      ],
      extraParameters: [
        ...makeArray(
          numberOfExtraParameters,
          DeviceMockFactory.createDeviceModelParameter
        ),
        ...(data?.extraParameters || [])
      ]
    };
  }

  static createDeviceModelVariant() {
    return {
      name: randAlphaString(40),
      parameters: makeArray(4, DeviceMockFactory.createDeviceModelParameter)
    };
  }

  static createDeviceModelParameter(): DeviceModelParameters {
    const type = rand(['string', 'number', 'boolean']);

    let defaultValue: string = '';
    let possibleValues: string[] = [];

    switch (type) {
      case 'string':
        defaultValue = randAlphaString(10);
        possibleValues = randBoolean()
          ? [randAlphaString(5), randAlphaString(6), randAlphaString(7)]
          : [];
        break;
      case 'number':
        defaultValue = randNumber().toString();
        possibleValues = randBoolean()
          ? [
              randNumber().toString(),
              randNumber().toString(),
              randNumber().toString()
            ]
          : [];
        break;
      case 'boolean':
        defaultValue = randBoolean().toString();
        possibleValues = randBoolean() ? ['true', 'false'] : [];
        break;
    }

    return {
      name: randAlphaString(40),
      type,
      unit: rand(['kg', 'm/s', 'kWh', 'W']),
      defaultValue,
      possibleValues,
      overridable: enumValueFaker(OverridableEnum),
      description: randAlphaString(100)
    };
  }
}
