import { months } from '@utils/reporting-utils';

import { Grid } from '@destination/components';

import { AnalyticsRecord } from '@omnis-pulse-types';

export const ReportingTableRow = ({
  siteName,
  data,
  index
}: {
  siteName: string;
  data: AnalyticsRecord[] | undefined;
  index: number;
}) => {
  return (
    <Grid.TableRow
      data-testid={`table-row-${index}`}
      alternateRowColor={index % 2 !== 0}
    >
      <Grid.DataCell>{siteName}</Grid.DataCell>
      {months.map((month, idx) => {
        const monthData = data
          ? data.find(monthData => monthData.date === month)
          : undefined;
        return (
          <Grid.DataCell key={`row-${index}-cell-${idx}`}>
            {monthData ? monthData.value : 'N/A'}
          </Grid.DataCell>
        );
      })}
    </Grid.TableRow>
  );
};
