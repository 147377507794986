import { connectorNumberMapper } from '@utils/connector-mapper';
import {
  formatDate,
  getDifferenceBetweenDates,
  timeFormat
} from '@utils/date-utils';
import { roundNumber } from '@utils/utils';

import { Grid } from '@destination/components';

import {
  ChargerActiveSession,
  ChargerFinishedSession
} from '@omnis-pulse-types';

export interface IChargerSessionRow {
  session: ChargerActiveSession | ChargerFinishedSession;
  index: number;
}

export const ChargerSessionRow = ({ session, index }: IChargerSessionRow) => {
  const isHistorySession = isChargerSessionFinished(session);

  return (
    <Grid.TableRow
      alternateRowColor={isHistorySession && index % 2 !== 0}
      className={isHistorySession ? '' : '[&>div]:bg-[#E2F7DF]'}
    >
      <Grid.DataCell className="text-center">
        {connectorNumberMapper(session.connectorId)}
      </Grid.DataCell>
      <Grid.DataCell>{formatDate(session.startTime)}</Grid.DataCell>
      <TimeGridDataCell
        startTime={session.startTime}
        endTime={isHistorySession ? session.endTime : undefined}
      />
      <DurationGridDataCell
        startTime={session.startTime}
        endTime={isHistorySession ? session.endTime : undefined}
      />
      <Grid.DataCell>{`${roundNumber(session.energy, 3)} kWh`}</Grid.DataCell>
    </Grid.TableRow>
  );
};

const TimeGridDataCell = ({
  startTime,
  endTime
}: {
  startTime: string;
  endTime?: string;
}) => {
  const formattedStartTime = formatDate(startTime, timeFormat);
  const formattedEndTime = endTime ? formatDate(endTime, timeFormat) : '-- --';
  return (
    <Grid.DataCell>
      {formattedStartTime} - {formattedEndTime}
    </Grid.DataCell>
  );
};

const DurationGridDataCell = ({
  startTime,
  endTime
}: {
  startTime: string;
  endTime?: string;
}) => {
  const duration = getDifferenceBetweenDates(
    endTime ? new Date(endTime).getTime() : Date.now(),
    new Date(startTime).getTime()
  );
  return <Grid.DataCell>{duration}</Grid.DataCell>;
};

const isChargerSessionFinished = (
  session: ChargerActiveSession | ChargerFinishedSession
): session is ChargerFinishedSession => {
  return 'endTime' in session;
};
