import { useDateFilter } from '@hooks/use-date-filter';
import { usePortalTranslation } from '@hooks/use-portal-translation';
import { useParams } from 'react-router-dom';

import useChargerEventLog from '@api/chargers/use-charger-event-log';
import useChargerDetails from '@api/sites/chargers/use-charger-details';
import { DateTimeFilterButtons } from '@components/buttons/DateTimeFilterButtons';
import { TabLabel } from '@components/TabLabel';
import { LogsContainer } from '@components/table/logs/LogsContainer';

import { Card, LoadingSkeleton, Typography } from '@destination/components';

export const Logs = () => {
  const { t } = usePortalTranslation();

  const { id: siteId, chargerId } = useParams();
  const { selectedFilter, fromTimestamp, toTimestamp, handleFilterChange } =
    useDateFilter();

  const params = new URLSearchParams();

  if (fromTimestamp) params.append('from', fromTimestamp.toString());
  if (toTimestamp) params.append('to', toTimestamp.toString());

  const { charger, isLoading: isLoadingCharger } = useChargerDetails(
    siteId,
    chargerId
  );
  const {
    logs,
    isLoading,
    isLoadingMore,
    isError,
    hasReachedEnd,
    handleLoadMore
  } = useChargerEventLog(chargerId, true, params);

  const title = t('labels.entity_event_logs', { entity: charger?.name });

  return (
    <>
      {charger && <TabLabel label={title} />}
      <div className="mb-8">
        {isLoadingCharger ? (
          <LoadingSkeleton isDarker className="w-full max-w-sm" height={30.4} />
        ) : (
          <Typography variant="pageTitle">{title}</Typography>
        )}
      </div>
      <DateTimeFilterButtons
        selectedFilter={selectedFilter}
        onFilterChange={handleFilterChange}
      />
      <Card className="!shadow-none">
        <LogsContainer
          isError={isError}
          isLoadingInitial={isLoading}
          isLoadingMore={isLoadingMore}
          isCompleted={hasReachedEnd}
          logs={logs}
          onLoadMore={handleLoadMore}
        />
      </Card>
    </>
  );
};
