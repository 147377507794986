import useSWR from 'swr';

import { PRODUCT_TYPE_DEVICE_MODELS_BY_SITE_ID_PATH } from '@api/paths';

import { DeviceModels, ProductType } from '@omnis-pulse-types';

function createQueryString(
  params?: Record<string, string | Record<string, string>>,
  prefix = ''
): string {
  if (!params || Object.keys(params).length === 0) return '';
  const query = Object.entries(params)
    .map(([key, value]) => {
      const fullKey = prefix ? `${prefix}[${key}]` : key;
      if (typeof value === 'object' && value !== null) {
        return createQueryString(value, fullKey);
      }
      return `${encodeURIComponent(fullKey)}=${encodeURIComponent(value)}`;
    })
    .join('&');
  return query;
}

export default function useProductTypeDeviceModels(
  siteId?: string,
  productTypeIdentifier?: ProductType['identity']
) {
  const queryParams = productTypeIdentifier
    ? { productType: productTypeIdentifier }
    : undefined;

  const queryString = createQueryString(queryParams);

  let endpoint: string | null = null;
  if (siteId) {
    const basePath = PRODUCT_TYPE_DEVICE_MODELS_BY_SITE_ID_PATH(siteId);
    endpoint = queryString ? `${basePath}?${queryString}` : basePath;
  }

  const { data, error, isLoading, mutate } = useSWR<DeviceModels>(endpoint);

  return { deviceModels: data, isLoading, isError: Boolean(error), mutate };
}
