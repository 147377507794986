import { usePortalTranslation } from '@hooks/use-portal-translation';

import { LegendItem } from '@components/charts/LegendItem';
import {
  formatDate,
  formatDurationInHoursAndMinutes,
  timeFormat
} from '@utils/date-utils';

import { Typography } from '@destination/components';

import { EvseStatusEnum } from '@omnis-pulse-types';

interface CustomTooltipProps {
  active?: boolean;
  payload?: Array<{
    payload: {
      x: number;
      z: number;
      status: EvseStatusEnum;
      energyConsumed?: number;
    };
  }>;
}

export const EvseStatusChartTooltip = (props: CustomTooltipProps) => {
  const { t } = usePortalTranslation();

  const { active, payload } = props;
  if (active && payload && payload.length > 0) {
    const status = payload[0].payload.status;
    const statusLegendStyle = getStatusLegendStyle(status);
    return (
      <div className="w-max-[200px] rounded-[4px] bg-white p-2 drop-shadow-[0_0_15px_rgba(0,0,0,0.2)]">
        <div className="grid grid-cols-[auto_auto] gap-2">
          <Typography className="pl-2.5" variant="smallText">
            {t('literals.timestamp', { titleize: true })}
          </Typography>
          <Typography className="font-bold" variant="smallText">
            {formatDate(
              new Date(payload[0].payload.x).toISOString(),
              timeFormat
            )}
          </Typography>
          <Typography className="pl-2.5" variant="smallText">
            {t('literals.duration', { titleize: true })}
          </Typography>
          <Typography className="font-bold" variant="smallText">
            {formatDurationInHoursAndMinutes(
              payload[0].payload.z,
              payload[0].payload.x
            )}
          </Typography>
          {payload[0].payload.energyConsumed && (
            <>
              <Typography variant="smallText">
                {t('literals.energy', { titleize: true })}
              </Typography>
              <Typography className="font-bold" variant="smallText">
                {payload[0].payload.energyConsumed}
              </Typography>
            </>
          )}
          <LegendItem
            style={{ ...statusLegendStyle }}
            label={t(`states.${payload[0].payload.status.toLowerCase()}`)}
          />
        </div>
      </div>
    );
  }

  return null;
};

const getStatusLegendStyle = (status: string | undefined) => {
  switch (status) {
    case EvseStatusEnum.ERROR:
      return {
        backgroundImage:
          "url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI2IiBoZWlnaHQ9IjYiIHZpZXdCb3g9IjAgMCA2IDYiIGZpbGw9Im5vbmUiPjxjaXJjbGUgY3g9IjMiIGN5PSIzIiByPSIzIiBmaWxsPSIjRkZDOUNEIi8+PHBhdGggZD0iTTEuNzYyNyAxLjc2MjQ1TDQuMjM3NTcgNC4yMzczMiIgc3Ryb2tlPSIjRUYzOTM0Ii8+PHBhdGggZD0iTTQuMjM4MjggMS43NjI0NUwxLjc2MzQxIDQuMjM3MzIiIHN0cm9rZT0iI0VGMzkzNCIvPjwvc3ZnPg==')",
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center'
      };
    case EvseStatusEnum.AVAILABLE:
      return {
        backgroundColor: '#99D794'
      };
    case EvseStatusEnum.CHARGING:
      return {
        backgroundColor: '#9FBEF7'
      };
    case EvseStatusEnum.UNKNOWN:
      return {
        backgroundImage:
          'url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20width%3D%226%22%20height%3D%226%22%20viewBox%3D%220%200%206%206%22%20fill%3D%22none%22%3E%3Cg%20clip-path%3D%22url(%23clip0_14633_76541)%22%3E%3Ccircle%20cx%3D%223%22%20cy%3D%223%22%20r%3D%223%22%20fill%3D%22%23FFD3A8%22/%3E%3Cpath%20d%3D%22M11.5%20-4L-3%2010.5%22%20stroke%3D%22%23FFA200%22/%3E%3Cpath%20d%3D%22M8.5%20-4L-6%2010.5%22%20stroke%3D%22%23FFA200%22/%3E%3C/g%3E%3Cdefs%3E%3CclipPath%20id%3D%22clip0_14633_76541%22%3E%3Crect%20width%3D%226%22%20height%3D%226%22%20rx%3D%223%22%20fill%3D%22white%22/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E")',
        backgroundSize: 'cover'
      };
    default:
      return {};
  }
};
