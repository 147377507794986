import { useEffect } from 'react';

import { usePortalTranslation } from '@hooks/use-portal-translation';
import {
  useAppInsightsContext,
  useTrackEvent
} from '@microsoft/applicationinsights-react-js';
import { FallbackProps } from 'react-error-boundary';

import SadIcon from '@icons/sad_face_96.svg';

import { Button, Typography } from '@destination/components';

export const GeneralFallback = ({
  error,
  resetErrorBoundary
}: FallbackProps) => {
  const { t: t_errors, t_generic } = usePortalTranslation('errors');
  const appInsights = useAppInsightsContext();
  const trackError = useTrackEvent(appInsights, 'GeneralError', error);

  useEffect(() => {
    trackError(error);
  }, [error, trackError]);

  return (
    <div className="w-full text-center">
      <div className="flex h-8">
        <Typography className="mx-auto mt-auto !leading-4" variant="smallTitle">
          {t_generic('application_name')}
        </Typography>
      </div>
      <div className="mb-8 flex justify-center" data-testid="sad-icon">
        <SadIcon />
      </div>
      <div className="flex flex-col justify-center text-center">
        <Typography variant="smallTitle" className="mb-6">
          {t_errors('something_went_wrong')}
        </Typography>
        <Typography variant="description">
          {t_errors('go_back_and_try_again')}
        </Typography>
        <div className="tw-mt-6 tw-flex tw-justify-center">
          <Button variant="primary" onClick={resetErrorBoundary}>
            <Typography variant="smallText">
              {t_errors('buttons.try_again', { titleize: true })}
            </Typography>
          </Button>
        </div>
      </div>
    </div>
  );
};
