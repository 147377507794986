import { usePortalTranslation } from '@hooks/use-portal-translation';
import { NavLink } from 'react-router-dom';

import { Button } from '@destination/components';

export interface IGridMeterActionButtons {
  isProcessing: boolean;
  isAddButtonDisabled: boolean;
  onAdd: () => void;
}

export const GridMeterActionButtons = ({
  isProcessing,
  isAddButtonDisabled,
  onAdd
}: IGridMeterActionButtons) => {
  const { t } = usePortalTranslation();

  const cancelButtonLabel = t('buttons.cancel', { titleize: true });
  const addButtonLabel = t('buttons.add', {
    entity: t('literals.grid_meter'),
    titleize: true
  });

  return (
    <div className="mt-12 flex justify-center gap-6">
      <NavLink to=".." relative="path">
        <Button variant="ghost" disabled={isProcessing}>
          {cancelButtonLabel}
        </Button>
      </NavLink>
      <Button
        data-testid="add-grid-meter-button"
        variant="primary"
        disabled={isAddButtonDisabled}
        isLoading={isProcessing}
        onClick={onAdd}
      >
        {addButtonLabel}
      </Button>
    </div>
  );
};
