import { usePortalTranslation } from '@hooks/use-portal-translation';

import { months } from '@utils/reporting-utils';

import { Grid, TableLoading, Typography } from '@destination/components';

import { AnalyticsRecord, ModelUsageReport, Site } from '@omnis-pulse-types';

import { ReportingTableRow } from './ReportingTableRow';

interface ReportingTableProps {
  isLoading: boolean;
  data: ModelUsageReport[];
  sums: AnalyticsRecord[];
}

export const ReportingTable = ({
  isLoading,
  data,
  sums
}: ReportingTableProps) => {
  const { t, t_generic } = usePortalTranslation('reporting');
  const monthLabels = months.map(month => t_generic(`labels.months.${month}`));

  return (
    <div className="overflow-x-auto rounded-[6px] bg-white shadow-md">
      <Grid
        data-testid="reporting-table"
        className="grid min-w-[800px] grid-cols-[300px_repeat(12,1fr)] p-6"
      >
        <Grid.HeadCell>
          {t_generic('labels.entity_name', {
            entity: t_generic('literals.site')
          })}
        </Grid.HeadCell>
        {monthLabels.map((label, idx) => (
          <Grid.HeadCell key={`month-label-${months[idx]}`}>
            {label}
          </Grid.HeadCell>
        ))}

        {isLoading && <TableLoading className="col-span-13 mt-3" />}

        {!isLoading &&
          data.map((siteData, index) => {
            const siteName =
              siteData.subjectType === 'Site' &&
              (siteData.subject as Site)?.label;

            return (
              <ReportingTableRow
                key={(siteData.subject as Site).id}
                index={index}
                siteName={siteName || t('unknown_site', { titleize: true })}
                data={siteData.records}
              />
            );
          })}

        {!isLoading && (
          <Grid.TableRow data-testid="summary-row">
            <Grid.DataCell>
              <Typography variant="smallTitle">
                {t('subtitle', { titleize: true })}
              </Typography>
            </Grid.DataCell>
            {months.map(month => {
              const sumRecord = sums.find(
                record => record.date?.toLowerCase() === month
              );

              return (
                <Grid.DataCell
                  key={`summary-cell-${month}`}
                  data-testid={`summary-cell-${month}`}
                  className="font-bold"
                >
                  {sumRecord?.value ?? 'N/A'}
                </Grid.DataCell>
              );
            })}
          </Grid.TableRow>
        )}
      </Grid>
    </div>
  );
};
