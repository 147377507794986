import { usePortalTranslation } from '@hooks/use-portal-translation';

import { Typography } from '@destination/components';

interface Last2MonthsOverviewProps {
  data: { number: number; month: string }[];
}

export const Last2MonthsOverview = ({ data }: Last2MonthsOverviewProps) => {
  const { t } = usePortalTranslation('reporting');

  return (
    <>
      <Typography variant="description" className="mt-2">
        {t('2_months_overview.description', { titleize: true })}
      </Typography>

      <div className="mt-3 h-[100px] w-96 rounded-[10px] bg-white shadow-[0_0_20px_0_rgba(0,0,0,0.10)]">
        <div className="relative grid h-full grid-cols-2">
          {data.map(({ number, month }, index) => (
            <div
              key={month}
              className="relative flex flex-col items-center justify-center"
            >
              {index === 1 && (
                <div
                  data-testid="border"
                  className="absolute bottom-3 left-0 top-3 border-l border-gray-300"
                />
              )}
              <Typography variant="pageTitle">{number}</Typography>
              <Typography variant="description" className="mt-2">
                {month}
              </Typography>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};
