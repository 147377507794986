import { useState } from 'react';

import { usePortalTranslation } from '@hooks/use-portal-translation';
import { useParams } from 'react-router-dom';
import { mutate } from 'swr';

import { SITE_BY_ID_PATH, SITE_PATH } from '@api/paths';
import updateSite from '@api/sites/update-site';
import { countryCodes } from '@constants/countryCodes';
import { isNotEmpty } from '@utils/input-utils';
import { getCountryName } from '@utils/utils';

import {
  Dialog,
  Input,
  IOption,
  Select,
  useAlert,
  useInput
} from '@destination/components';

import { Site } from '@omnis-pulse-types';

export interface IEditSiteForm {
  showDialog: boolean;
  site: Site;
  onClose: () => void;
}

const countryOptions: IOption[] = countryCodes.map((countryCode): IOption => {
  const countryName = getCountryName('en-US', countryCode);
  return { label: countryName ?? 'unknown', value: countryCode };
});

export const EditSiteForm = ({ site, showDialog, onClose }: IEditSiteForm) => {
  // Computed values
  const initialCountryOption =
    countryOptions.find(({ value }) => value === site.address?.countryCode) ??
    null;

  const [isUpdating, setIsUpdating] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState<IOption | null>(
    initialCountryOption
  );

  const { id } = useParams() as { id: string };

  const { t } = usePortalTranslation();
  const { t: tSites } = usePortalTranslation('sites');
  const { t: tErrors } = usePortalTranslation('errors');

  const { notifySuccess, notifyError } = useAlert();

  const {
    value: nameValue,
    isValid: nameIsValid,
    valueChangeHandler: nameChangeHandler,
    reset: nameReset
  } = useInput(isNotEmpty, site ? site.label : '');

  const {
    value: addressValue,
    valueChangeHandler: addressChangeHandler,
    reset: addressReset
  } = useInput(() => true, site.address?.addressLine ?? '');

  const handleClose = () => {
    nameReset();
    setSelectedCountry(initialCountryOption);
    addressReset();

    onClose();
  };

  const handleConfirm = async () => {
    setIsUpdating(true);

    const { error } = await updateSite(id, {
      label: nameValue,
      address: addressValue,
      countryCode: selectedCountry?.value as string
    });

    if (error) {
      notifyError({
        header: tErrors('failed_to_save_settings', {
          entity: t('literals.site', { titleize: true })
        })
      });

      nameReset();
      setSelectedCountry(initialCountryOption);
      addressReset();
    } else {
      notifySuccess({
        header: t('labels.save_settings_success', {
          entity: t('literals.site', { titleize: true })
        })
      });

      await mutate(
        key => typeof key === 'string' && key.startsWith(`${SITE_PATH}?`),
        undefined,
        { revalidate: true }
      );
      await mutate(SITE_BY_ID_PATH(id));

      onClose();
    }

    setIsUpdating(false);
  };

  const editSiteForm = (
    <form className="mb-2 mt-[35px] flex flex-col gap-[43px]">
      <Input
        id="name"
        label={t('literals.name', { titleize: true })}
        placeholder={t('labels.entity_name', {
          entity: t('literals.site', { titleize: true })
        })}
        value={nameValue}
        error={!nameIsValid}
        helperText={
          !nameIsValid
            ? tSites('messages.site_name_cannot_be_empty')
            : undefined
        }
        onChange={event => nameChangeHandler(event.target.value)}
        data-testid="name-input"
      />
      <Select
        label={t('literals.country', { titleize: true })}
        enableSearch={true}
        searchPlaceholder={t('labels.search_placeholder', {
          entity: t('literals.country')
        })}
        placeholder={t('labels.select_entity', {
          entity: t('literals.country')
        })}
        options={countryOptions}
        selected={selectedCountry}
        helperText={
          !selectedCountry
            ? t('labels.select_entity', {
                entity: t('literals.country')
              })
            : undefined
        }
        onChange={option => setSelectedCountry(option)}
        data-testid="country-input"
      />
      <Input
        id="address"
        data-testid="address-input"
        label={t('literals.address', { titleize: true })}
        placeholder={tSites('literals.site_address', { titleize: true })}
        value={addressValue}
        onChange={event => addressChangeHandler(event.target.value)}
      />
    </form>
  );

  return (
    <Dialog
      data-testid="edit-site"
      header={t('buttons.edit', {
        entity: t('literals.details'),
        titleize: true
      })}
      isOpen={showDialog}
      isConfirmDisabled={!nameIsValid}
      isLoading={isUpdating}
      confirmButton={t('buttons.save', { titleize: true })}
      cancelButton={t('buttons.cancel', { titleize: true })}
      onConfirm={handleConfirm}
      onCancel={handleClose}
      onClose={handleClose}
      content={editSiteForm}
    />
  );
};
