import { usePortalTranslation } from '@hooks/use-portal-translation';
import { NavLink } from 'react-router-dom';

import useGateway from '@api/gateways/use-gateway';
import { useSiteDevicesContext } from '@context/SiteDevicesContext';
import GridMeterIcon from '@icons/grid_meter_96.svg';
import PlusIcon from '@icons/plus_16.svg';

import {
  Button,
  LoadingSkeleton,
  NothingConfigured,
  Tooltip,
  Typography
} from '@destination/components';

import { ConnectionStatusEnum } from '@omnis-pulse-types';

export const GridMeterNotConfigured = () => {
  const { t, t_generic } = usePortalTranslation('gridMeters');

  const { deviceData } = useSiteDevicesContext();

  const { gateway, isLoading, isError } = useGateway(deviceData.gateways[0].id);

  const isGatewayOnline =
    gateway?.connectionStatus.find(status => status.type === 'ABB')?.status ===
    ConnectionStatusEnum.CONNECTED;

  const title = t_generic('labels.no_configured', {
    entity: t_generic('literals.grid_meter')
  });
  const buttonLabel = t_generic('buttons.add', {
    entity: t_generic('literals.grid_meter'),
    titleize: true
  });

  const isButtonDisabled = isError || !isGatewayOnline;

  if (isLoading) {
    return (
      <div className="flex h-full items-center justify-center">
        <LoadingSkeleton className="rounded-[10px]" height={431} />
      </div>
    );
  }

  return (
    <div className="flex h-full items-center justify-center">
      <NothingConfigured title={title} icon={<GridMeterIcon />}>
        <NavLink to="add">
          <Tooltip
            message={
              isButtonDisabled ? (
                <div className="max-w-[267px]">
                  <Typography variant="smallTitle">
                    {t('tooltips.tgp_offline.title')}
                  </Typography>
                  <Typography variant="description">
                    {t('tooltips.tgp_offline.description')}
                  </Typography>
                </div>
              ) : null
            }
          >
            <Button
              variant="primary"
              icon={<PlusIcon />}
              disabled={isButtonDisabled}
            >
              {buttonLabel}
            </Button>
          </Tooltip>
        </NavLink>
      </NothingConfigured>
    </div>
  );
};
