import { usePortalTranslation } from '@hooks/use-portal-translation';
import { useParams } from 'react-router-dom';

import useSiteGridMeterTelemetry from '@api/sites/use-site-grid-meter-telemetry';
import { useSiteDevicesContext } from '@context/SiteDevicesContext';
import { YAxisTickProvider } from '@context/YAxisTickContext';

import {
  Card,
  DataError,
  LoadingSkeleton,
  Typography
} from '@destination/components';

import { SiteOverviewChart } from './SiteOverviewChart';
import { SiteOverviewCurrentChart } from './SiteOverviewCurrentChart';

export const SiteOverview = () => {
  const { id } = useParams();
  const { t } = usePortalTranslation();
  const { data, isLoading, isError, isNotFound, mutate } =
    useSiteGridMeterTelemetry(id);
  const { deviceData } = useSiteDevicesContext();
  const renderContent = () => {
    if (id === undefined) return null;

    if (isError && !isNotFound) {
      return (
        <Card className="h-full">
          <div className="flex h-full w-full items-center">
            <DataError onRefresh={() => mutate(undefined, true)} />
          </div>
        </Card>
      );
    }

    if (isLoading) {
      return (
        <LoadingSkeleton
          isDarker
          className="!h-full rounded-[10px]"
          height={0}
        />
      );
    }

    if (
      data === undefined ||
      (data.current || []).length === 0 ||
      !deviceData.hasGridMeter ||
      isNotFound
    ) {
      return (
        <Card className="h-full">
          <SiteOverviewCurrentChart siteId={id} />
        </Card>
      );
    }

    return (
      <Card className="h-full">
        <YAxisTickProvider>
          <SiteOverviewChart data={data} />
        </YAxisTickProvider>
      </Card>
    );
  };

  return (
    <div
      className="flex h-full w-full flex-col"
      data-testid="energy-delivered-graph"
    >
      <div className="mb-3 flex flex-col gap-1">
        <Typography variant="sectionTitle">
          {t('labels.entity_overview', {
            entity: t('literals.site', { titleize: true })
          })}
        </Typography>
        <Typography variant="description">
          {t('date_time.last_x_hours_other', {
            titleize: true,
            count: 24
          })}
        </Typography>
      </div>
      <div className="min-h-[304px] w-full flex-1">{renderContent()}</div>
    </div>
  );
};
