import { usePortalTranslation } from '@hooks/use-portal-translation';
import { NavLink, Outlet } from 'react-router-dom';

import { PageHeader } from '@components/general/PageHeader';
import { TabLabel } from '@components/TabLabel';

import { Tab } from '@destination/components';

export const settingsTabs = [
  { label: 'navigation.account', path: '' },
  { label: 'navigation.language', path: '/language' },
  { label: 'navigation.notifications', path: '/notifications' }
];

export const Settings = () => {
  const { t } = usePortalTranslation('settings');
  const basePath = `/settings`;

  return (
    <div>
      <TabLabel label={t('page_title')} />
      <PageHeader title={t('page_title')} />
      <nav className="tw-inline-flex gap-4">
        {settingsTabs.map(tab => (
          <NavLink key={tab.label} to={`${basePath}${tab.path}`} end={true}>
            {({ isActive }) => (
              <Tab data-testid={`tab-${tab.label}`} isActive={isActive}>
                {t(tab.label, { titleize: true })}
              </Tab>
            )}
          </NavLink>
        ))}
      </nav>
      <section className="mt-6 flex grow flex-col">
        <Outlet />
      </section>
    </div>
  );
};
