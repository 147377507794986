import { usePortalTranslation } from '@hooks/use-portal-translation';

import OfflineIcon from '@icons/offline_16.svg';
import OnlineIcon from '@icons/online_16.svg';
import { dateTimeFormat, formatDate } from '@utils/date-utils';

import { Tooltip } from '@destination/components';

import { ConnectionStatus, ConnectionStatusEnum } from '@omnis-pulse-types';

interface IConnectionStatusOverview {
  connectionStatus: ConnectionStatus;
}

export const ConnectionStatusOverview = ({
  connectionStatus
}: IConnectionStatusOverview) => {
  const { t } = usePortalTranslation();

  let StatusIcon;
  let statusText;

  switch (connectionStatus.status) {
    case ConnectionStatusEnum.CONNECTED:
      StatusIcon = OnlineIcon;
      statusText = t('states.connected', { titleize: true });
      break;
    case ConnectionStatusEnum.NOT_CONNECTED:
      StatusIcon = OfflineIcon;
      statusText = t('states.disconnected', { titleize: true });
      break;
    default:
      StatusIcon = OfflineIcon;
      statusText = t('states.unknown', { titleize: true });
  }

  return (
    <span data-testid="connection-status" className="flex items-center gap-2">
      <div className="h-4 w-4">
        <StatusIcon />
      </div>
      <Tooltip
        message={`${t('literals.last_update', { titleize: true })} ${formatDate(connectionStatus.timestamp, dateTimeFormat)}`}
      >
        <span>{statusText}</span>
      </Tooltip>
    </span>
  );
};
