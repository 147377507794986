import { usePortalTranslation } from '@hooks/use-portal-translation';

import { DetailLine } from '@components/DetailsLine';
import {
  dateFormat,
  formatDate,
  formatDurationInHoursAndMinutes,
  timeFormat
} from '@utils/date-utils';
import { getSocketTypeIcon } from '@utils/socket-utils';
import { ConvertWattToKilowatt } from '@utils/utils';

import {
  ChargerActiveSession,
  EvseStatus,
  SocketTypeEnum
} from '@omnis-pulse-types';

interface EvseSessionDetailsProps {
  evse: EvseStatus;
  session: ChargerActiveSession;
}

export const EvseSessionDetails = ({
  evse,
  session
}: EvseSessionDetailsProps) => {
  const { t, t_generic } = usePortalTranslation('chargers');

  const activeSocket = evse.sockets.find(
    socket => socket.id == session.connectorId
  );

  return (
    <div className="mt-4">
      <DetailLine
        label={t_generic('literals.evse', { titleize: true })}
        className="mb-4"
      >
        <span className="flex text-base font-bold">
          {activeSocket &&
            (activeSocket.type === SocketTypeEnum.UNUSED
              ? SocketTypeEnum.UNKNOWN
              : activeSocket.type)}
          {activeSocket && getSocketTypeIcon(activeSocket.type)}
        </span>
      </DetailLine>
      <DetailLine
        label={t_generic('literals.starting_date', { titleize: true })}
        className="mb-4"
      >
        <span className="text-base font-bold">
          {formatDate(session.startTime, dateFormat)}
        </span>
      </DetailLine>
      <DetailLine
        label={t_generic('literals.time', { titleize: true })}
        className="mb-4"
      >
        <span className="text-base font-bold">
          {formatDate(session.startTime, timeFormat)}-...
        </span>
      </DetailLine>
      <DetailLine
        label={t_generic('literals.duration', { titleize: true })}
        className="mb-4"
      >
        <span className="text-base font-bold">
          {formatDurationInHoursAndMinutes(
            Date.now(),
            new Date(session.startTime).getTime()
          )}
        </span>
      </DetailLine>
      <DetailLine
        label={t('literals.energy_delivered', { titleize: true })}
        className="mb-4"
      >
        <span className="text-base font-bold">{`${session.energy} kWh`}</span>
      </DetailLine>
      <DetailLine
        label={t_generic('literals.power', { titleize: true })}
        className="mb-4"
      >
        <span className="text-base font-bold">{`${ConvertWattToKilowatt(session.power).toFixed(2)} kW`}</span>
      </DetailLine>
    </div>
  );
};
