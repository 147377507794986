import { InteractionStatus } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';

import { msalInstance } from '@config/authConfig';
import { getAccessToken } from '@utils/msal-utils';

export interface IAuthProvider {
  children: React.ReactNode;
}

export const AuthProvider = ({ children }: IAuthProvider) => {
  const { inProgress } = useMsal();

  const initializeAndHandleRedirect = async () => {
    await msalInstance.initialize();
    const authResult = await msalInstance.handleRedirectPromise();

    if (authResult) await getAccessToken(true);
  };

  initializeAndHandleRedirect();

  if (inProgress !== InteractionStatus.None) return <></>;

  return <>{children}</>;
};
