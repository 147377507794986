import React from 'react';

import useChargerActiveSessions from '@api/sites/chargers/use-charger-active-sessions';
import { getIsChargerConnected } from '@utils/charger-utils';

import { ChargerDetails } from '@omnis-pulse-types';

import { ChargerConnectionStatus } from './ChargerConnectionStatus';
import { ChargerEvseStatus } from './ChargerEvseStatus';

export interface IChargerStatus {
  siteId?: string;
  charger: ChargerDetails;
}

export const ChargerStatus = ({ siteId, charger }: IChargerStatus) => {
  const { evseStatuses } = charger;

  const isChargerConnected = getIsChargerConnected(charger);

  const { sessions } = useChargerActiveSessions(
    isChargerConnected ? siteId : undefined,
    isChargerConnected ? charger.id : undefined
  );

  return (
    <div className="flex gap-2">
      <ChargerConnectionStatus charger={charger} />
      {evseStatuses.map(evseStatus => (
        <React.Fragment key={evseStatus.id}>
          <div className="h-full w-[1px] bg-[#EBEBEB]" />
          <ChargerEvseStatus
            evseStatus={evseStatus}
            sessions={sessions ?? []}
          />
        </React.Fragment>
      ))}
    </div>
  );
};
