import { useEffect, useState } from 'react';

import { usePortalTranslation } from '@hooks/use-portal-translation';

import updateOrganizationUserRoles from '@api/user-account/update-organization-user-roles';
import useUserDetails from '@api/user-account/use-user-details';

import {
  Checkbox,
  Dialog,
  Typography,
  useAlert
} from '@destination/components';

import { ApplicationRole, UserDetails } from '@omnis-pulse-types';

export interface IEditUserRolesDialog {
  handleCloseDialog: () => void;
  user?: UserDetails;
  onUpdate: () => Promise<void>;
}

export const EditUserRolesDialog = ({
  handleCloseDialog,
  user,
  onUpdate
}: IEditUserRolesDialog) => {
  const { t, t_generic } = usePortalTranslation('accessManagement');
  const { t: t_errors } = usePortalTranslation('errors');
  const { notifyError } = useAlert();

  const { userDetails } = useUserDetails();

  const [isUpdating, setIsUpdating] = useState(false);
  const [selectedRoles, setSelectedRoles] = useState<ApplicationRole[]>([]);

  useEffect(() => {
    setSelectedRoles(user ? user.applicationRoles : []);
  }, [user]);

  const toggleRole = (role: ApplicationRole) => {
    if (selectedRoles.includes(role)) {
      setSelectedRoles(prevSelectedRoles =>
        prevSelectedRoles.filter(prevRole => prevRole !== role)
      );
    } else {
      setSelectedRoles(prevSelectedRoles => [...prevSelectedRoles, role]);
    }
  };

  const handleConfirm = async () => {
    if (user === undefined) {
      return;
    }

    setIsUpdating(true);
    const { error } = await updateOrganizationUserRoles(user.id, {
      applicationRoles: selectedRoles
    });

    if (error) {
      notifyError({
        header: t_errors('failed_to_update', {
          entity: t_generic('literals.user')
        })
      });
    } else {
      await onUpdate();
      handleCloseDialog();
    }
    setIsUpdating(false);
  };

  const editDialogContent = user ? (
    <div data-testid="edit-dialog-content">
      <Typography className="my-4" variant="smallTitle">
        {t('literals.user_identity', { titleize: true })}
      </Typography>
      <div className="flex flex-col gap-2 pb-2">
        <Typography variant="description">{user.displayName}</Typography>
        <Typography variant="description">{user.email}</Typography>
      </div>
      <Typography className="my-4" variant="smallTitle">
        {t('labels.assign_role', { titleize: true })}
      </Typography>
      <div className="flex flex-col gap-4 pb-[23px]">
        <Checkbox
          label={t('roles.service_manager', { titleize: true })}
          checked={selectedRoles.includes(ApplicationRole.SERVICE_MANAGER)}
          onChange={() => toggleRole(ApplicationRole.SERVICE_MANAGER)}
        />
        <Checkbox
          label={t('roles.service_engineer', { titleize: true })}
          checked={selectedRoles.includes(ApplicationRole.SERVICE_ENGINEER)}
          onChange={() => toggleRole(ApplicationRole.SERVICE_ENGINEER)}
        />
      </div>
    </div>
  ) : null;

  return (
    <Dialog
      data-testid="edit-dialog"
      isOpen={user !== undefined}
      header={t('labels.edit_user_role_for_organization', {
        organization: userDetails?.organization.name
      })}
      content={editDialogContent}
      confirmButton={t_generic('buttons.save', { titleize: true, entity: '' })}
      isConfirmDisabled={!selectedRoles.length}
      onClose={handleCloseDialog}
      onCancel={handleCloseDialog}
      onConfirm={handleConfirm}
      isLoading={isUpdating}
    />
  );
};
