import { SITE_CHARGING_STRATEGY_PATH } from '@api/paths';
import { modifier } from '@utils/swr-utils';

import { UpdateSiteChargingStrategy } from '@omnis-pulse-types';

export default async function updateSiteChargingStrategy(
  siteId: string,
  payload: UpdateSiteChargingStrategy
) {
  const url = SITE_CHARGING_STRATEGY_PATH(siteId);
  return await modifier(url, 'PATCH', payload);
}
