import { usePortalTranslation } from '@hooks/use-portal-translation';
import { useOutletContext } from 'react-router-dom';

import { GatewayActions } from '@components/gateways/actions/GatewayActions';
import { ConnectedChargersTable } from '@components/gateways/ConnectedChargersTable';
import { GatewayEventLogs } from '@components/gateways/GatewayEventLogs';
import { GatewayProperties } from '@components/gateways/GatewayProperties';

import { Typography } from '@destination/components';

import { Gateway as GatewayModel } from '@omnis-pulse-types';

export const Gateway = () => {
  const gateway = useOutletContext<GatewayModel>();

  const { t } = usePortalTranslation('gateways');

  const sectionTitle = t('labels.gateway_information', { titleize: true });
  const smallTitle = t('labels.gateway_properties', { titleize: true });

  return (
    <>
      <Typography className="mb-4" variant="sectionTitle">
        {sectionTitle}
      </Typography>
      <Typography className="mb-4" variant="smallTitle">
        {smallTitle}
      </Typography>
      <div className="mb-12 flex flex-wrap gap-x-[30px] gap-y-4">
        <GatewayProperties gateway={gateway} />
        <GatewayActions />
      </div>
      <ConnectedChargersTable gatewayId={gateway.id} />
      <GatewayEventLogs gatewayId={gateway.id} />
    </>
  );
};
