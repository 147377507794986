import { useEffect } from 'react';

import { usePortalTranslation } from '@hooks/use-portal-translation';
import {
  useAppInsightsContext,
  useTrackEvent
} from '@microsoft/applicationinsights-react-js';
import { useNavigate, useRouteError } from 'react-router-dom';

import SadIcon from '@icons/sad_face_96.svg';

import { Button, Typography } from '@destination/components';

export const RouteErrorFallback = () => {
  const appInsights = useAppInsightsContext();
  const error = useRouteError();
  const { t } = usePortalTranslation('errors');
  const trackError = useTrackEvent(appInsights, 'RouteError', error);
  const navigate = useNavigate();

  useEffect(() => {
    trackError(error);
  }, [error, trackError]);

  return (
    <div className="flex h-full w-full flex-col justify-center text-center">
      <div className="mb-8 flex justify-center" data-testid="sad-icon">
        <SadIcon />
      </div>
      <div className="flex flex-col justify-center text-center">
        <Typography variant="smallTitle" className="mb-6">
          {t('something_went_wrong')}
        </Typography>
        <Typography variant="description">
          {t('go_back_and_try_again')}
        </Typography>
        <div className="mt-6 flex justify-center">
          <Button variant="primary" onClick={() => navigate(-1)}>
            <Typography variant="smallText">
              {t('buttons.go_back', { titleize: true })}
            </Typography>
          </Button>
        </div>
      </div>
    </div>
  );
};
