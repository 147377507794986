import { DEVICES_BY_SITE_ID_PATH } from '@api/paths';
import { modifier } from '@utils/swr-utils';

import { CreateDeviceForSiteRequestBody } from '@omnis-pulse-types';

export default function createSiteDevice(
  siteId: string,
  payload: CreateDeviceForSiteRequestBody
) {
  const url = DEVICES_BY_SITE_ID_PATH(siteId);
  return modifier(url, 'POST', payload);
}
