import { useCallback } from 'react';

import { usePortalTranslation } from '@hooks/use-portal-translation';
import useSWR from 'swr';
import useSWRMutation from 'swr/mutation';

import apiClient from '@api/apiClient';
import { SITE_LIMITS_PATH } from '@api/paths';
import { fetcher } from '@utils/swr-utils';

import { useAlert } from '@destination/components';

import type {
  SiteLoadManagementLimits,
  UpdateSiteLoadManagementLimits
} from '@omnis-pulse-types';

export type UseSiteLoadManagementLimitsReturn = {
  data: SiteLoadManagementLimits | undefined;
  isLoading: boolean;
  isError: boolean;
  isUpdating: boolean;
  update: (payload: UpdateSiteLoadManagementLimits) => Promise<void>;
  mutate: () => Promise<SiteLoadManagementLimits | undefined>;
};

export async function updateSiteLoadManagementLimitsLimits(
  url: string,
  { arg }: { arg: UpdateSiteLoadManagementLimits }
) {
  return apiClient.patch(url, arg);
}

export default function useSiteLoadManagementLimits(
  siteId?: string
): UseSiteLoadManagementLimitsReturn {
  const { t, t_generic } = usePortalTranslation('errors');
  const { notify, notifyError } = useAlert();

  const successMsg = t_generic('labels.save_settings_success', {
    entity: t_generic('literals.limits'),
    titleize: true
  });
  const errorMsg = t('failed_to_save_settings', {
    entity: t_generic('literals.limits')
  });

  const { data, isLoading, error, mutate } = useSWR<SiteLoadManagementLimits>(
    siteId ? SITE_LIMITS_PATH(siteId) : null,
    fetcher
  );

  const { trigger, isMutating: isUpdating } = useSWRMutation(
    siteId ? SITE_LIMITS_PATH(siteId) : null,
    updateSiteLoadManagementLimitsLimits,
    {
      rollbackOnError: true,
      revalidate: true,
      onSuccess: () => {
        notify({ variant: 'success', header: successMsg });
      },
      onError: () => {
        notifyError({ header: errorMsg });
      }
    }
  );

  const updateLimits = useCallback(
    async (payload: UpdateSiteLoadManagementLimits) => {
      await trigger(payload);
      mutate();
    },
    [trigger, mutate]
  );

  return {
    data,
    isLoading,
    isError: Boolean(error),
    isUpdating,
    update: updateLimits,
    mutate
  };
}
