import useSWR from 'swr';

import { DEVICES_BY_SITE_ID_PATH } from '@api/paths';

import { DeviceCategory, ProductType } from '@omnis-pulse-types';

export default function useSiteDevicesProductTypes(
  siteId: string | undefined,
  deviceCategory: DeviceCategory
) {
  const { data, error, isLoading, mutate } = useSWR<ProductType[]>(
    siteId
      ? `${DEVICES_BY_SITE_ID_PATH(siteId)}/product-types?deviceCategory=${deviceCategory}`
      : null
  );

  return { productTypes: data, isLoading, isError: Boolean(error), mutate };
}
