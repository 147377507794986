import { usePortalTranslation } from '@hooks/use-portal-translation';

import hardRebootGateway from '@api/gateways/hard-reboot-gateway';

import { Button, useAlert, useDialog } from '@destination/components';

interface IGatewayActionsRestartDevice {
  gatewayId: string;
}

export const GatewayActionsRestartDevice = ({
  gatewayId
}: IGatewayActionsRestartDevice) => {
  const { t, t_generic } = usePortalTranslation('gateways');

  const restartDevice = t_generic('buttons.restart', {
    entity: t_generic('literals.device'),
    titleize: true
  });

  const { trigger } = useDialog();
  const { notifySuccess, notifyError } = useAlert();

  const handleRestartDevice = () => {
    const content = t('labels.restart_device_confirmation');

    trigger({
      variant: 'warning',
      header: restartDevice,
      content,
      confirmButton: t_generic('buttons.confirm', { titleize: true }),
      cancelButton: t_generic('buttons.cancel', { titleize: true }),
      onCancel: () => undefined,
      onClose: () => undefined,
      onConfirm: async () => {
        const { error } = await hardRebootGateway(gatewayId);

        if (error) {
          notifyError({ header: t('labels.restart_device_failed') });
        } else {
          notifySuccess({ header: t('labels.restart_device_success') });
        }
      }
    });
  };

  return (
    <Button
      data-testid="restart-device-button"
      variant="ghost"
      onClick={handleRestartDevice}
    >
      {restartDevice}
    </Button>
  );
};
