import { usePortalTranslation } from '@hooks/use-portal-translation';

import { Grid, TableLoading } from '@destination/components';

import {
  ChargerActiveSession,
  ChargerFinishedSession
} from '@omnis-pulse-types';

import { ChargerSessionRow } from './ChargerSessionRow';

type sessions = ChargerActiveSession[] | ChargerFinishedSession[];

export interface IChargerSessionTable {
  sessions: sessions;
  isLoading: boolean;
  isSingleLoadingRow?: boolean;
}

export const ChargerSessionTable = ({
  sessions,
  isLoading,
  isSingleLoadingRow
}: IChargerSessionTable) => {
  return (
    <>
      <Grid className="grid-cols-[1fr_1fr_1fr_1fr_1fr] grid-rows-[1fr]">
        <Header />
        {isLoading === false && <Content sessions={sessions ?? []} />}
      </Grid>
      {isLoading && (
        <TableLoading
          isSingleLoadingRow={isSingleLoadingRow}
          className="mt-3"
        />
      )}
    </>
  );
};

const Header = () => {
  const { t, t_generic } = usePortalTranslation('chargers');
  return (
    <div className="contents [&>div]:mb-1">
      <Grid.HeadCell className="!text-center">
        {t('literals.connector_no')}
      </Grid.HeadCell>
      <Grid.HeadCell>{t_generic('literals.starting_date')}</Grid.HeadCell>
      <Grid.HeadCell>{t_generic('literals.time')}</Grid.HeadCell>
      <Grid.HeadCell>{t_generic('literals.duration')}</Grid.HeadCell>
      <Grid.HeadCell>{t('literals.energy_delivered')}</Grid.HeadCell>
    </div>
  );
};

const Content = ({ sessions }: { sessions: sessions }) => {
  return (
    <>
      {sessions.map((session, index) => (
        <ChargerSessionRow key={session.id} index={index} session={session} />
      ))}
    </>
  );
};
