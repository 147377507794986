import { usePortalTranslation } from '@hooks/use-portal-translation';
import { useParams } from 'react-router-dom';

import useSiteLoadManagementFallbackBudget from '@api/sites/use-site-load-management-fallback-budget';
import { useSiteDevicesContext } from '@context/SiteDevicesContext';
import { isValidPositiveNumber } from '@utils/input-utils';

import {
  AutomaticSaveInput,
  Card,
  DataError,
  Disclaimer,
  LoadingSkeleton,
  Typography,
  useAlert
} from '@destination/components';

export const FallbackBudget = () => {
  const { t, t_generic } = usePortalTranslation('siteSettings');
  const { t: t_errors } = usePortalTranslation('errors');
  const { id } = useParams();
  const { data, isLoading, isError, mutate, update, isUpdating } =
    useSiteLoadManagementFallbackBudget(id);

  const { deviceData } = useSiteDevicesContext();
  const { notifyError } = useAlert();

  const errorMessage = t_errors('failed_to_save_settings', {
    entity: t('literals.fallback_budget'),
    titleize: true
  });

  const label = t('fallback_budget.label', { titleize: true });
  const invalidHelperText = t_errors('invalid_input', {
    titleize: true
  });
  const errorHelperText = t_errors('failed_to_save', {
    entity: t_generic('literals.capacity')
  });

  const helperText = t('fallback_budget.helper_text', {
    titleize: true,
    value: deviceData.chargers?.length
  });

  const handleSave = async (value: string) => {
    const newFallbackBudget = {
      ...data,
      evseFallbackBudget: parseFloat(value)
    };

    try {
      await update(newFallbackBudget);
    } catch {
      mutate();
      notifyError({ header: errorMessage });
    }
  };

  const renderContent = () => {
    if (isLoading) {
      return <LoadingSkeleton height={20} />;
    }

    if (isError) {
      return <DataError onRefresh={() => mutate()} />;
    }

    if (data === undefined) {
      return null;
    }

    return (
      <div className="flex flex-col gap-4 px-3 py-4">
        <Disclaimer
          variant="warning"
          message={t('fallback_budget.warning_message')}
        />
        <div className="flex items-center gap-4">
          <div className="min-w-[200px]">
            <Typography>{label}</Typography>
          </div>
          <div className="w-[200px]">
            <AutomaticSaveInput
              disabled={isUpdating}
              data-testid="fallback-budget-input"
              initialValue={data.evseFallbackBudget.toString()}
              id="fallback-budget-value"
              validator={isValidPositiveNumber}
              invalidHelperText={invalidHelperText}
              errorHelperText={errorHelperText}
              onSave={handleSave}
              suffixText={<Typography variant="smallTitle">A</Typography>}
              helperText={helperText}
            />
          </div>
        </div>
      </div>
    );
  };

  return <Card className="w-[620px]">{renderContent()}</Card>;
};
