import fetchUserTokenHint from '@api/user-account/fetch-user-token-hint';
import { loginRequest, msalInstance } from '@config/authConfig';

export async function getAccessToken(forceRefresh = false) {
  const account = msalInstance.getAllAccounts()[0];
  if (account) {
    const request = {
      scopes: [import.meta.env.VITE_APP_BACKEND_API_SCOPE],
      account,
      forceRefresh
    };
    const authenticationResult = await msalInstance
      .acquireTokenSilent(request)
      .catch(async () => {
        // Call logoutRedirect in case of acquireTokenSilent failure
        await msalInstance.logoutRedirect();
      });
    return authenticationResult?.accessToken;
  }
}

export async function switchOrganization(organizationOmnisId: string) {
  const account = msalInstance.getAllAccounts()[0];
  const idTokenHint = await fetchUserTokenHint(organizationOmnisId);

  await msalInstance.acquireTokenRedirect({
    ...loginRequest,
    account,
    extraQueryParameters: { id_token_hint: idTokenHint.tokenHint },
    redirectStartPage: '/'
  });
}
