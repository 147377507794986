import { usePermission } from '@hooks/use-permission';
import { usePortalTranslation } from '@hooks/use-portal-translation';
import { useNavigate, useParams } from 'react-router-dom';
import { mutate } from 'swr';

import { SITE_PATH } from '@api/paths';
import useChargers from '@api/sites/chargers/use-chargers';
import deleteSite from '@api/sites/delete-site';
import { Action, ResourceType } from '@constants/Permissions';

import {
  BulletMenu,
  Tooltip,
  Typography,
  useAlert,
  useDialog
} from '@destination/components';

import { Site } from '@omnis-pulse-types';

interface IDeleteSiteMenuItem {
  site: Site;
}

export const DeleteSiteMenuItem = ({ site }: IDeleteSiteMenuItem) => {
  const { id = '' } = useParams();
  const navigate = useNavigate();

  const { trigger } = useDialog();
  const { notify, notifyError } = useAlert();

  const isAllowed = usePermission();

  const { chargers } = useChargers(id);

  const { t } = usePortalTranslation();
  const { t: tSites } = usePortalTranslation('sites');

  const handleClickDeleteMenuItem = () => {
    const deleteFailedMsg = tSites('messages.command_to_delete_site_failed', {
      site: site.label
    });
    const deleteSuccessfulMsg = tSites(
      'messages.command_to_delete_site_successful',
      { site: site.label }
    );
    const dialogHeader = `${t('buttons.delete', { entity: site.label })}?`;
    const dialogDescription = tSites('messages.delete_site_warning');
    const dialogConfirmButtonLabel = t('buttons.delete', {
      titleize: true
    });

    const _notifyError = () => {
      notifyError({
        header: deleteFailedMsg,
        'data-testid': 'site-delete-failed-alert'
      });
    };

    const _notifySuccess = () => {
      notify({
        variant: 'success',
        header: deleteSuccessfulMsg,
        isDismissible: true,
        'data-testid': 'site-delete-successful-alert'
      });
    };

    const handleConfirm = async () => {
      const { error } = await deleteSite(site.id);

      if (error) {
        _notifyError();
      } else {
        _notifySuccess();
        await mutate(
          key => typeof key === 'string' && key.startsWith(`${SITE_PATH}?`),
          undefined,
          { revalidate: true }
        );
        navigate('..', { relative: 'path' });
      }
    };

    trigger({
      header: dialogHeader,
      content: dialogDescription,
      confirmButton: dialogConfirmButtonLabel,
      variant: 'error',
      onConfirm: handleConfirm,
      onCancel: () => undefined,
      'data-testid': 'confirm-delete-site-dialog'
    });
  };

  const tooltipMessage = (
    <div className="max-w-[267px] break-words">
      <Typography variant="smallText" className="mb-[4px]">
        {tSites('messages.cannot_delete_site_with_devices')}
      </Typography>
    </div>
  );

  return (
    <>
      {isAllowed(Action.DELETE, ResourceType.SITE, site) ? (
        <Tooltip
          message={(chargers ?? []).length > 0 ? tooltipMessage : ''}
          placement="bottom-center"
        >
          <BulletMenu.MenuItem
            onClick={handleClickDeleteMenuItem}
            disabled={(chargers ?? []).length > 0}
          >
            {t('buttons.delete', {
              entity: t('literals.site'),
              titleize: true
            })}
          </BulletMenu.MenuItem>
        </Tooltip>
      ) : (
        <></>
      )}
    </>
  );
};
