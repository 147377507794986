import { ComponentProps } from 'react';

import { usePortalTranslation } from '@hooks/use-portal-translation';

import {
  AutomaticSaveRadioGroup,
  RadioGroup,
  Typography
} from '@destination/components';

import {
  ChargingStrategy as ChargingStrategyEnum,
  UpdateSiteChargingStrategy
} from '@omnis-pulse-types';

type Options = ComponentProps<typeof RadioGroup>['options'];

const strategies = [
  ChargingStrategyEnum.EQUAL_POWER,
  ChargingStrategyEnum.EQUAL_SHARE,
  ChargingStrategyEnum.FIFO
];

export interface IChargingStrategySelector {
  strategy: ChargingStrategyEnum;
  onSave: (payload: UpdateSiteChargingStrategy) => Promise<void>;
}

export const ChargingStrategySelector = ({
  strategy,
  onSave
}: IChargingStrategySelector) => {
  const { t, t_generic } = usePortalTranslation('siteSettings');

  const handleSave = async (strategy: string | number) => {
    await onSave({ strategy: strategy as ChargingStrategyEnum });
  };

  // Generate the options dynamically based on the strategy enum
  const options: Options = strategies.map(strategy => ({
    label: t(`strategy.${strategy.toLowerCase()}`, { titleize: true }),
    description: t(`strategy.${strategy.toLowerCase()}_description`, {
      titleize: true
    }),
    value: strategy
  }));
  const header = t_generic('labels.entity_strategy', {
    entity: t_generic('literals.charging'),
    titleize: true
  });
  const errorToastMessage = t('failed_to_save_settings', {
    entity: t_generic('literals.charging_strategy')
  });

  return (
    <div className="flex flex-col gap-4">
      <Typography variant="smallTitle">{header}</Typography>
      <AutomaticSaveRadioGroup
        initialValue={strategy}
        options={options}
        errorToastMessage={errorToastMessage}
        onSave={handleSave}
      />
    </div>
  );
};
