import { Card, Typography } from '@destination/components';

import { DeviceModelParameters } from '@omnis-pulse-types';

import { ParameterInputs } from './ParameterInput';

export const GridMeterSettingsCard = ({
  title,
  parameters,
  onParameterChange,
  setIsValidForm,
  isDisabled
}: {
  title: string;
  parameters: DeviceModelParameters[];
  onParameterChange: (
    updatedParameters: { name: string; value: string }[]
  ) => void;
  setIsValidForm: (isValid: boolean) => void;
  isDisabled: boolean;
}) => (
  <Card
    data-testid="grid-meter-extra-settings-card"
    className="max-w-[620px] py-6"
  >
    <div className="max-w-[620px] py-6">
      <Typography variant="smallTitle" className="mb-6 ml-3">
        {title}
      </Typography>
      <ParameterInputs
        parameters={parameters}
        onParameterChange={onParameterChange}
        setIsValidForm={setIsValidForm}
        isDisabled={isDisabled}
      />
    </div>
  </Card>
);
