import { usePortalTranslation } from '@hooks/use-portal-translation';
import { useParams } from 'react-router-dom';

import { Drawer } from '@destination/components';

import { GatewayActionsRestartApplication } from './GatewayActionsRestartApplication';
import { GatewayActionsRestartDevice } from './GatewayActionsRestartDevice';

export interface IGatewayActionsDrawer {
  isOpen: boolean;
  onClose: () => void;
}

export const GatewayActionsDrawer = ({
  isOpen,
  onClose
}: IGatewayActionsDrawer) => {
  const { gatewayId = '' } = useParams();

  const { t } = usePortalTranslation();

  const title = t('labels.entity_actions', {
    entity: t('literals.service'),
    titleize: true
  });

  return (
    <Drawer title={title} isOpen={isOpen} onClose={onClose}>
      <div className="flex justify-center gap-4">
        <GatewayActionsRestartApplication gatewayId={gatewayId} />
        <GatewayActionsRestartDevice gatewayId={gatewayId} />
      </div>
    </Drawer>
  );
};
