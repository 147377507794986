import { useCallback } from 'react';

import { usePortalTranslation } from '@hooks/use-portal-translation';
import useSWR from 'swr';
import useSWRMutation from 'swr/mutation';

import apiClient from '@api/apiClient';
import { SITE_FALLBACK_BUDGET_PATH } from '@api/paths';
import { fetcher } from '@utils/swr-utils';

import { useAlert } from '@destination/components';

import { SiteFallbackBudget } from '@omnis-pulse-types';

export type UseSiteLoadManagementFallbackBudgetReturn = {
  data: SiteFallbackBudget | undefined;
  isLoading: boolean;
  isError: boolean;
  isUpdating: boolean;
  update: (payload: SiteFallbackBudget) => Promise<void>;
  mutate: () => Promise<SiteFallbackBudget | undefined>;
};

async function updateSiteLoadManagementFallbackBudget(
  url: string,
  { arg }: { arg: SiteFallbackBudget }
) {
  return apiClient.patch(url, arg);
}

export default function useSiteLoadManagementFallbackBudget(
  siteId?: string
): UseSiteLoadManagementFallbackBudgetReturn {
  const { notify, notifyError } = useAlert();
  const { t, t_generic } = usePortalTranslation('errors');

  const successMsg = t_generic('labels.save_settings_success', {
    entity: t_generic('literals.fallback_budget'),
    titleize: true
  });
  const errorMsg = t('failed_to_save_settings', {
    entity: t_generic('literals.fallback_budget')
  });

  const { data, error, isLoading, mutate } = useSWR<SiteFallbackBudget>(
    siteId ? SITE_FALLBACK_BUDGET_PATH(siteId) : null,
    fetcher
  );

  const { trigger, isMutating: isUpdating } = useSWRMutation(
    siteId ? SITE_FALLBACK_BUDGET_PATH(siteId) : null,
    updateSiteLoadManagementFallbackBudget,
    {
      rollbackOnError: true,
      revalidate: true,
      onSuccess: () => {
        notify({ variant: 'success', header: successMsg });
      },
      onError: () => {
        notifyError({ header: errorMsg });
      }
    }
  );

  const update = useCallback(
    async (payload: SiteFallbackBudget) => {
      await trigger(payload);
      mutate();
    },
    [trigger, mutate]
  );

  return {
    data,
    isLoading,
    isError: Boolean(error),
    isUpdating,
    update,
    mutate
  };
}
