import { usePortalTranslation } from '@hooks/use-portal-translation';

import { Input } from '@destination/components';

export interface IEditChargerNameForm {
  name: string;
  onNameChange: (newName: string) => void;
}

export const EditChargerNameForm = ({
  name,
  onNameChange
}: IEditChargerNameForm) => {
  const { t } = usePortalTranslation();

  const label = t('literals.name', { titleize: true });
  const placeholder = t('labels.entity_name', {
    entity: t('literals.charger', { titleize: true })
  });

  return (
    <form className="mb-2 mt-[35px]">
      <Input
        data-testid="edit-charger-name-input"
        id="name"
        label={label}
        placeholder={placeholder}
        value={name}
        onChange={event => onNameChange(event.target.value)}
      />
    </form>
  );
};
