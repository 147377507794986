import { useState } from 'react';

import { usePortalTranslation } from '@hooks/use-portal-translation';

import ServiceIcon from '@icons/service_48.svg';

import { Card, Typography } from '@destination/components';

import { GatewayActionsDrawer } from './GatewayActionsDrawer';

export const GatewayActions = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const { t, t_generic } = usePortalTranslation('gateways');

  const title = t_generic('labels.entity_actions', {
    entity: t_generic('literals.service'),
    titleize: true
  });
  const description = t('labels.restart_application_restart_device');

  return (
    <>
      <button
        className="h-min min-w-96 flex-1 text-left"
        onClick={() => setIsDrawerOpen(true)}
        onKeyDown={event => {
          if (event.key === 'Enter') {
            setIsDrawerOpen(true);
          }
        }}
      >
        <Card
          data-testid="service-actions-card"
          className="!rounded-md hover:cursor-pointer hover:drop-shadow-hover"
        >
          <div className="my-2 flex gap-4">
            <ServiceIcon />
            <div>
              <Typography variant="sectionTitle">{title}</Typography>
              <Typography>{description}</Typography>
            </div>
          </div>
        </Card>
      </button>
      <GatewayActionsDrawer
        isOpen={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
      />
    </>
  );
};
