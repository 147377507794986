import useSWR from 'swr';

import { CHARGER_FINISHED_SESSIONS_PATH } from '@api/paths';

import { PaginatedChargerFinishedSession } from '@omnis-pulse-types';

export default function useChargerFinishedSessions(
  siteId?: string,
  chargerId?: string,
  pageSize = '20'
) {
  let url = null;

  if (siteId && chargerId) {
    const params = new URLSearchParams();

    params.append('pageSize', pageSize);

    url = `${CHARGER_FINISHED_SESSIONS_PATH(siteId, chargerId)}?${params.toString()}`;
  }

  const { data, isLoading, error, mutate } =
    useSWR<PaginatedChargerFinishedSession>(url);

  return {
    sessions: data?.data,
    isLoading,
    isError: Boolean(error),
    mutate
  };
}
