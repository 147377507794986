import {
  TOverloadedFunction,
  usePortalTranslation
} from '@hooks/use-portal-translation';

import { switchOrganization } from '@utils/msal-utils';

import { Typography, useAlert } from '@destination/components';

import { UserOrganizationDetails } from '@omnis-pulse-types';

export interface IOrganizationRow {
  organization: UserOrganizationDetails;
  isLast: boolean;
  onClose: () => void;
}

export const OrganizationRow = ({
  organization,
  isLast,
  onClose
}: IOrganizationRow) => {
  const { t } = usePortalTranslation('account');

  const { isActive, name, roles, organizationOmnisId } = organization;

  const { notifyError } = useAlert();

  const handleClick = async () => {
    if (isActive) {
      onClose();
      return;
    }
    try {
      await switchOrganization(organizationOmnisId);
    } catch {
      notifyError({
        header: t('messages.could_not_switch_organization')
      });
    }
  };

  return (
    <button
      data-testid={`organization-row-${organization.organizationOmnisId}`}
      className={isLast ? '' : 'border-b border-[#EBEBEB]'}
      onClick={handleClick}
    >
      <div className="my-0.5 cursor-pointer rounded-[4px] px-1 pb-1 pt-[3px] text-left hover:bg-[#F5F5F5] hover:font-bold *:hover:block">
        <Typography className={`select-none ${isActive && 'font-bold'}`}>
          {name}
        </Typography>
        {roles && (
          <Typography
            className={`mt-1 !text-[12px] font-bold ${!isActive && 'hidden'}`}
          >
            {getRolesLabel(roles, t)}
          </Typography>
        )}
      </div>
    </button>
  );
};

const getRolesLabel = (roles: string[], t: TOverloadedFunction) => {
  const noRolesLabel = t('messages.no_roles');
  const yourRoleLabel = t('messages.your_role');
  const yourRolesLabel = t('messages.your_role_other');

  if (roles.length === 0) {
    return noRolesLabel;
  }
  if (roles.length === 1) {
    const roleLabel = t(`literals.${roles[0].toLowerCase()}`);
    return `${yourRoleLabel}: ${roleLabel}`;
  }
  if (roles.length === 2) {
    const roleOneLabel = t(`literals.${roles[0].toLowerCase()}`);
    const roleTwoLabel = t(`literals.${roles[1].toLowerCase()}`);
    return `${yourRolesLabel}: ${roleOneLabel}, ${roleTwoLabel}`;
  }

  const roleOneLabel = t(`literals.${roles[0].toLowerCase()}`);
  const xMoreRolesLabel = t('messages.x_more_roles', {
    count: roles.length - 1
  });
  return `${yourRolesLabel}: ${roleOneLabel} ${xMoreRolesLabel}`;
};
