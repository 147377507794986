import { useState } from 'react';

import { usePortalTranslation } from '@hooks/use-portal-translation';

import { EditSiteForm } from '@components/sites/siteHeader/EditSiteForm';

import { BulletMenu } from '@destination/components';

import { Site } from '@omnis-pulse-types';

import { DeleteSiteMenuItem } from './DeleteSiteMenuItem';
import { SiteNameAndAddress } from './SiteNameAndAddress';

export interface ISiteHeader {
  site?: Site;
  isLoading: boolean;
}

export const SiteHeader = ({ site, isLoading }: ISiteHeader) => {
  const { t } = usePortalTranslation();

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  if (!isLoading && site === undefined) {
    return null;
  }

  const editDetailsLabel = t('buttons.edit', {
    entity: t('literals.details'),
    titleize: true
  });

  return (
    <div className="mb-6 flex justify-between" data-testid="site-header">
      <SiteNameAndAddress site={site} isLoading={isLoading} />
      <div className="flex gap-4">
        <BulletMenu popoverPosition="bottom-left" disabled={isLoading}>
          <BulletMenu.MenuItem onClick={() => setIsDialogOpen(true)}>
            {editDetailsLabel}
          </BulletMenu.MenuItem>
          <DeleteSiteMenuItem site={site!} />
        </BulletMenu>
      </div>
      {site && (
        <EditSiteForm
          showDialog={isDialogOpen}
          site={site}
          onClose={() => setIsDialogOpen(false)}
        />
      )}
    </div>
  );
};
