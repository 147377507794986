import { usePortalTranslation } from '@hooks/use-portal-translation';

import { IOption, Select, Typography } from '@destination/components';

export interface IGridMeterManufacturerSelector {
  isDisabled: boolean;
  isLoading: boolean;
  options: IOption[];
  selected: IOption | null;
  onChange: (option: IOption) => void;
}

export const GridMeterManufacturerSelector = ({
  isDisabled,
  isLoading,
  options,
  selected,
  onChange
}: IGridMeterManufacturerSelector) => {
  const { t, t_generic } = usePortalTranslation('gridMeters');

  const label = t_generic('literals.manufacturer', { titleize: true });
  const placeholder = t('placeholders.choose_manufacturer');
  const searchPlaceholder = t('placeholders.search_manufacturer');

  return (
    <div>
      <Typography>{label}</Typography>
      <div className="w-full max-w-[342px]">
        <Select
          disabled={isDisabled}
          isLoading={isLoading}
          enableSearch
          searchPlaceholder={searchPlaceholder}
          placeholder={placeholder}
          options={options}
          selected={selected}
          onChange={onChange}
        />
      </div>
    </div>
  );
};
