import { IOption } from '@destination/components';

import { ProductType } from '@omnis-pulse-types';

export const getManufacturerOptions = (
  productTypes: ProductType[]
): IOption[] => {
  const uniqueManufacturers = new Set(
    productTypes
      .filter(({ manufacturer }) => manufacturer !== undefined)
      .map(({ manufacturer }) => manufacturer!)
  );

  return Array.from(uniqueManufacturers).map(manufacturer => ({
    value: manufacturer,
    label: manufacturer
  }));
};

export const getTypeOptions = (
  productTypes: ProductType[],
  manufacturer: IOption | null
): IOption[] => {
  if (manufacturer === null) {
    return [];
  }

  return productTypes
    .filter(({ manufacturer: m }) => m === manufacturer.value)
    .filter(({ model }) => model !== undefined)
    .map(({ model }) => ({
      value: model!,
      label: model!
    }));
};
