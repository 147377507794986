import { usePortalTranslation } from '@hooks/use-portal-translation';

import { DetailLine } from '@components/DetailsLine';
import InfoIcon from '@icons/info_16.svg';
import { isValidPositiveNumber, max } from '@utils/input-utils';

import { AutomaticSaveInput, Tooltip } from '@destination/components';

import { UpdateSiteLoadManagementLimits } from '@omnis-pulse-types';

export interface IFallbackChargingBudgetLimit {
  value: number | null;
  maxValue: number;
  isDisabled?: boolean;
  onSave: (payload: UpdateSiteLoadManagementLimits) => Promise<void>;
}

export const FallbackChargingBudgetLimit = ({
  value,
  maxValue,
  isDisabled,
  onSave
}: IFallbackChargingBudgetLimit) => {
  const { t } = usePortalTranslation('siteSettings');
  const { t: t_errors } = usePortalTranslation('errors');

  let className = 'items-baseline [&_strong]:text-[14px] [&>div]:w-[189px]';
  if (isDisabled) className += ' text-[#BABABA]';

  const infoIcon = (
    <Tooltip message="More info coming soon...">
      <InfoIcon data-testid="info-icon" className="[&>path]:fill-[#BABABA]" />
    </Tooltip>
  );

  const handleSave = async (value: string) => {
    const payload: UpdateSiteLoadManagementLimits = {
      fallbackChargingBudgetLimit: Number(value)
    };

    onSave(payload);
  };

  const validateInput = (value: string) =>
    isValidPositiveNumber(value) &&
    max(maxValue)(value) &&
    /^\d+(\.\d)?$/.test(value);

  const label = t('literals.fallback_charging_budget_limit', {
    titleize: true
  });
  const helperText = t('labels.max_value', {
    titleize: true,
    maxValue,
    unit: 'A'
  });
  const invalidHelperText = t_errors('invalid_input', {
    titleize: true
  });
  const errorHelperText = t_errors('failed_to_save', {
    entity: t('literals.charging_budget_limit')
  });

  return (
    <DetailLine icon={infoIcon} label={label} className={className}>
      <AutomaticSaveInput
        id="fallback-charging-budget-limit"
        data-testid="fallback-charging-budget-limit"
        initialValue={value?.toString()}
        disabled={isDisabled}
        validator={validateInput}
        helperText={helperText}
        invalidHelperText={invalidHelperText}
        errorHelperText={errorHelperText}
        onSave={handleSave}
        suffixText={<strong data-testid="unit">A</strong>}
      />
    </DetailLine>
  );
};
