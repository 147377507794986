import { useEffect } from 'react';

import { usePortalTranslation } from '@hooks/use-portal-translation';
import { useNavigate, useParams } from 'react-router-dom';

import { GridMeterDetails } from '@components/grid-meters/GridMeterDetails';
import { GridMeterNotConfigured } from '@components/grid-meters/GridMeterNotConfigured';
import { TabLabel } from '@components/TabLabel';
import { useSiteDevicesContext } from '@context/SiteDevicesContext';

export const GridMeters = () => {
  const { gridMeterId } = useParams();
  const navigate = useNavigate();
  const { t } = usePortalTranslation();

  const { deviceData } = useSiteDevicesContext();

  useEffect(() => {
    if (!gridMeterId && deviceData.gridMeters?.length) {
      navigate(`${deviceData.gridMeters[0].id}`);
    }
  }, [deviceData.gridMeters, gridMeterId, navigate]);

  if (deviceData.gridMeters.length === 0) {
    return <GridMeterNotConfigured />;
  }

  const gridMeter = deviceData.gridMeters.find(
    gridMeter => gridMeter.id === gridMeterId
  );
  const tabLabel =
    gridMeter?.name ?? t('literals.grid_meter', { titleize: true });

  return (
    <>
      <TabLabel label={tabLabel} />
      <GridMeterDetails gridMeters={deviceData.gridMeters} />
    </>
  );
};
