import { useEffect, useState } from 'react';

import { jwtDecode } from 'jwt-decode';

import { getNativeNameForLocale } from '@utils/locale-utils';
import { getAccessToken } from '@utils/msal-utils';

type DecodedToken = {
  email: string;
  language: string;
  name: string;
};

export const useDecodedToken = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [decodedToken, setDecodedToken] = useState<DecodedToken>();

  useEffect(() => {
    const getAndDecodeToken = async () => {
      const token = await getAccessToken();
      if (token) {
        const decoded = jwtDecode(token);

        if (decoded !== null && typeof decoded === 'object') {
          const decodedToken = decoded as DecodedToken;
          const language = getNativeNameForLocale(decodedToken.language);
          setDecodedToken({ ...decodedToken, language });
        }
      }

      setIsLoading(false);
    };

    getAndDecodeToken();
  }, []);

  return { isLoading, decodedToken };
};
