import { usePortalTranslation } from '@hooks/use-portal-translation';

import { DeviceStatus } from '@components/devices/DeviceStatus';
import { getEvseStatusIcon } from '@utils/charger-utils';
import { dateTimeFormat, formatDate } from '@utils/date-utils';
import { ConvertWattToKilowatt } from '@utils/utils';

import {
  ChargerActiveSession,
  EvseStatus,
  EvseStatusEnum,
  SocketStatus
} from '@omnis-pulse-types';

export interface IChargerEvseStatus {
  evseStatus: EvseStatus;
  sessions: ChargerActiveSession[];
}

export const ChargerEvseStatus = ({
  evseStatus,
  sessions
}: IChargerEvseStatus) => {
  const { t } = usePortalTranslation();

  const { status, timestamp, sockets } = evseStatus;

  const session = getSession(sessions, evseStatus.sockets);
  const socketType = session && getSocketType(session, sockets);

  let label = `${t('literals.evse').toUpperCase()} ${evseStatus.id}`;
  if (socketType) label += ` (${socketType})`;

  let value = t('states.' + status.toLowerCase(), {
    titleize: true
  });
  if (session && evseStatus.status === EvseStatusEnum.CHARGING) {
    const power = ConvertWattToKilowatt(session.power).toFixed(1);
    value += ` ${power} kW`;
  }

  const icon = getEvseStatusIcon(evseStatus.status);
  const formattedTimestamp = formatDate(timestamp, dateTimeFormat);
  const tooltip = `${t('literals.last_update', { titleize: true })} ${formattedTimestamp}`;

  return (
    <DeviceStatus label={label} value={value} icon={icon} tooltip={tooltip} />
  );
};

const getSession = (
  sessions: ChargerActiveSession[],
  sockets: SocketStatus[]
) => {
  return sessions.find(({ connectorId }) =>
    sockets.some(socket => socket.id === connectorId)
  );
};

const getSocketType = (
  session: ChargerActiveSession,
  sockets: SocketStatus[]
) => {
  return sockets.find(({ id }) => id === session.connectorId)?.type;
};
