import { useEffect, useState } from 'react';

import { usePortalTranslation } from '@hooks/use-portal-translation';
import { useNavigate, useParams } from 'react-router-dom';

import createSiteDevice from '@api/sites/create-site-device';
import { GridMeterActionButtons } from '@components/grid-meters/add-grid-meter/GridMeterActionButtons';
import { GridMeterManufacturerAndTypeCard } from '@components/grid-meters/add-grid-meter/GridMeterManufacturerAndTypeCard';
import { GridMeterProtocolCard } from '@components/grid-meters/add-grid-meter/GridMeterProtocolCard';
import { GridMeterSettingsCard } from '@components/grid-meters/add-grid-meter/GridMeterSettingsCard';

import { IOption, Typography, useAlert } from '@destination/components';

import {
  CreateDeviceForSiteRequestBody,
  DeviceCategory,
  DeviceModelParameters,
  ProductType
} from '@omnis-pulse-types';

const visibleParameterNames = [
  'host',
  'port',
  'unit_id',
  'addressing_mode',
  'byte_order'
];

export const Add = () => {
  const [manufacturer, setManufacturer] = useState<IOption | null>(null);
  const [type, setType] = useState<IOption | null>(null);
  const [productTypeIdentifier, setProductTypeIdentifier] = useState<
    ProductType['identity'] | null
  >(null);
  const [communicationParameters, setCommunicationParameters] = useState<
    DeviceModelParameters[]
  >([]);
  const [extraParameters, setExtraParameters] = useState<
    DeviceModelParameters[]
  >([]);

  const [gridMeterParameters, setGridMeterParameters] =
    useState<CreateDeviceForSiteRequestBody>({
      deviceCategory: DeviceCategory.METER,
      product: type?.value.toString(),
      auto_reload: true
    });

  const [isValidCommunicationsForm, setIsValidCommunicationsForm] =
    useState<boolean>(false);
  const [isValidExtraForm, setIsValidExtraForm] = useState<boolean>(false);

  const [isProcessing, setIsProcessing] = useState(false);

  const { id = '' } = useParams();

  const navigate = useNavigate();

  const { notifySuccess, notifyError } = useAlert();

  const { t, t_generic } = usePortalTranslation('gridMeters');

  const title = t_generic('buttons.add', {
    entity: t_generic('literals.grid_meter'),
    titleize: true
  });

  const handleAddGridMeter = async () => {
    setIsProcessing(true);

    const { error } = await createSiteDevice(id, gridMeterParameters);

    if (error) {
      notifyError({ header: t('notifications.error_adding_new_grid_meter') });
      setIsProcessing(false);
    } else {
      notifySuccess({ header: t('notifications.new_grid_meter_added') });
      navigate('..', { relative: 'path' });
    }
  };

  const handleChangeCommunicationSettings = (
    params: { value: string; name: string }[]
  ) => {
    setGridMeterParameters({
      ...gridMeterParameters,
      protocol: { variant: 'tcp', parameters: params }
    });
  };

  const handleChangeExtraParameters = (
    params: { value: string; name: string }[]
  ) => {
    setGridMeterParameters({ ...gridMeterParameters, specifications: params });
  };

  useEffect(() => {
    if (!communicationParameters || communicationParameters.length === 0) {
      setIsValidExtraForm(false);
    }
  }, [communicationParameters]);

  useEffect(() => {
    if (!extraParameters || extraParameters.length === 0) {
      setIsValidExtraForm(true);
    }
  }, [extraParameters]);

  return (
    <>
      <div className="flex flex-col gap-6">
        <Typography variant="pageTitle">{title}</Typography>
        <GridMeterManufacturerAndTypeCard
          isDisabled={isProcessing}
          manufacturer={manufacturer}
          type={type}
          setManufacturer={setManufacturer}
          setType={setType}
          setProductTypeIdentifier={setProductTypeIdentifier}
        />
        {productTypeIdentifier !== null && (
          <>
            <GridMeterProtocolCard
              isDisabled={isProcessing}
              productTypeIdentifier={productTypeIdentifier}
              setCommunicationParameters={setCommunicationParameters}
              setExtraParameters={setExtraParameters}
            />
            {communicationParameters.length > 0 && (
              <GridMeterSettingsCard
                title={t_generic('labels.entity_settings', {
                  entity: t_generic('literals.communication'),
                  titleize: true
                })}
                parameters={visibleParameterNames
                  .map(name =>
                    communicationParameters.find(param => param.name === name)
                  )
                  .filter(param => param !== undefined)}
                onParameterChange={handleChangeCommunicationSettings}
                setIsValidForm={setIsValidCommunicationsForm}
                isDisabled={isProcessing}
              />
            )}
            {extraParameters.length > 0 && (
              <GridMeterSettingsCard
                title={t_generic('labels.entity_settings', {
                  entity: t_generic('literals.special'),
                  titleize: true
                })}
                parameters={extraParameters}
                onParameterChange={handleChangeExtraParameters}
                setIsValidForm={setIsValidExtraForm}
                isDisabled={isProcessing}
              />
            )}
          </>
        )}
      </div>
      <GridMeterActionButtons
        isProcessing={isProcessing}
        isAddButtonDisabled={!isValidCommunicationsForm || !isValidExtraForm}
        onAdd={handleAddGridMeter}
      />
    </>
  );
};
