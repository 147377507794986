import { usePermission } from '@hooks/use-permission';
import { usePortalTranslation } from '@hooks/use-portal-translation';

import useUserDetails from '@api/user-account/use-user-details';
import useUserOrganizationDetails from '@api/user-account/use-user-organization-details';
import { OmnisRedirectButton } from '@components/accessManagement/OmnisRedirectButton';
import { OrganizationDetailCard } from '@components/accessManagement/OrganizationDetailCard';
import { OrganizationUsers } from '@components/accessManagement/OrganizationUsers';
import { PageHeader } from '@components/general/PageHeader';
import { TabLabel } from '@components/TabLabel';
import { Action, ResourceType } from '@constants/Permissions';

import { LoadingSkeleton, Typography } from '@destination/components';

export const AccessManagement = () => {
  const { t, t_generic } = usePortalTranslation('accessManagement');
  const isAllowed = usePermission();

  const {
    organizationDetails,
    isLoading: isLoadingOrganization,
    isError: isErrorOrganization
  } = useUserOrganizationDetails();

  const { userDetails, isLoading: isLoadingUserDetails } = useUserDetails();

  return (
    <>
      <TabLabel
        label={t_generic('labels.entity_management', {
          entity: t('literals.access'),
          titleize: true
        })}
      />
      {isLoadingUserDetails ? (
        <LoadingSkeleton isDarker height={40} className="mb-6 max-w-sm" />
      ) : (
        <div className="flex flex-row items-center justify-between">
          <PageHeader
            title={
              userDetails?.organization?.name ?? t('literals.my_organization')
            }
          />
          {isAllowed(Action.UPDATE, ResourceType.USER) && (
            <OmnisRedirectButton />
          )}
        </div>
      )}
      <OrganizationDetailCard
        organization={organizationDetails}
        isLoading={isLoadingOrganization}
        isError={isErrorOrganization}
        data-testid="organization-detail-card"
      />
      {isAllowed(Action.READ, ResourceType.USER) && (
        <>
          <Typography variant="sectionTitle" className="mb-4 mt-12">
            {t_generic('literals.user_other', { titleize: true })}
          </Typography>
          <OrganizationUsers />
        </>
      )}
    </>
  );
};
