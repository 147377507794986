import { usePortalTranslation } from '@hooks/use-portal-translation';

import useUserNotificationSettings from '@api/user-account/use-user-notification-settings';
import { TabLabel } from '@components/TabLabel';
import NotificationIcon from '@icons/notification_16.svg';

import {
  DataError,
  Grid,
  LoadingSkeleton,
  NothingConfigured,
  Typography
} from '@destination/components';

import { NotificationTypeBox } from './NotificationTypeBox';

export const Notifications = () => {
  const { t, t_generic } = usePortalTranslation('notifications');

  const { userNotificationSettings, isLoading, isError, mutate } =
    useUserNotificationSettings();

  const thereAreNotificationTypes = Boolean(
    Array.isArray(userNotificationSettings?.types) &&
      userNotificationSettings.types.length > 0
  );

  const renderPage = (content: React.ReactNode) => (
    <>
      <TabLabel
        label={`${t_generic('literals.settings_other', { titleize: true })} - ${t('title', { titleize: true })}`}
      />
      {content}
    </>
  );

  if (isError) {
    return renderPage(
      <div className="mt-32">
        <DataError
          variant="primary"
          onRefresh={() => mutate(undefined, true)}
        />
      </div>
    );
  }

  if (isLoading) {
    return renderPage(
      <LoadingSkeleton isDarker className="!h-full rounded-[10px]" height={0} />
    );
  }

  if (!isLoading && !thereAreNotificationTypes) {
    return renderPage(
      <div className="mt-32">
        <NothingConfigured
          title={t_generic('labels.no_configured_other', {
            entity: t_generic('literals.notification_other')
          })}
          icon={
            <div className="h-24 w-24">
              <NotificationIcon className="h-full w-full" />
            </div>
          }
        />
      </div>
    );
  }

  return renderPage(
    <>
      <Typography variant="description" className="mb-6">
        {t('customize_notification_preferences')}
      </Typography>
      <Grid>
        {userNotificationSettings?.types.map(type => (
          <NotificationTypeBox key={type.name} type={type} />
        ))}
      </Grid>
    </>
  );
};
