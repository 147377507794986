import React from 'react';

import { usePortalTranslation } from '@hooks/use-portal-translation';

import { DetailLine } from '@components/DetailsLine';
import { getSocketTypeIcon } from '@utils/socket-utils';

import { SocketTypeEnum } from '@omnis-pulse-types';

export interface IChargerSocketTypePropertyRow {
  socketTypes: SocketTypeEnum[];
}
export const ChargerSocketTypePropertyRow = ({
  socketTypes
}: IChargerSocketTypePropertyRow) => {
  const { t } = usePortalTranslation('chargers');

  const label = t('literals.outlet_type', { titleize: true });

  return (
    <DetailLine label={label}>
      <div className="flex gap-0.5 text-base font-bold">
        {socketTypes.map((socketType, index) => (
          <React.Fragment key={`${socketType}-${index}`}>
            {getSocketTypeIcon(socketType) && (
              <div>{getSocketTypeIcon(socketType)}</div>
            )}
            <span>{`${getSocketTypeLabel(socketType)}${index < socketTypes.length - 1 ? ', ' : ''}`}</span>
          </React.Fragment>
        ))}
      </div>
    </DetailLine>
  );
};

const getSocketTypeLabel = (socketType: SocketTypeEnum) => {
  return socketType === SocketTypeEnum.UNUSED
    ? SocketTypeEnum.UNKNOWN
    : socketType;
};
