import { usePortalTranslation } from '@hooks/use-portal-translation';

import { ChargersOverview } from '@components/charger/overview/ChargersOverview';
import { ChargersStateOverview } from '@components/sites/site/overview/ChargersStateOverview';
import { GatewayCard } from '@components/sites/site/overview/GatewayCard';
import { GridMeterCard } from '@components/sites/site/overview/GridMeterCard';
import { SiteOverview } from '@components/sites/site/overview/SiteOverview';
import { useSiteDevicesContext } from '@context/SiteDevicesContext';
import ChargerIcon from '@icons/charger_96.svg';

import { NothingConfigured, Typography } from '@destination/components';

export const Overview = () => {
  const { t, t_generic } = usePortalTranslation('sites');

  const { deviceData, isLoading: devicesLoading } = useSiteDevicesContext();

  if (devicesLoading) return null;

  if (deviceData.hasNoDevices) {
    return (
      <NothingConfigured
        title={t_generic('labels.no_configured_other', {
          entity: t_generic('literals.device_other')
        })}
        className="mt-32"
        icon={<ChargerIcon />}
      >
        <Typography className="max-w-[263px] text-center" variant="description">
          {t('once_configured.devices')}
        </Typography>
      </NothingConfigured>
    );
  }

  return (
    <>
      <div className="mb-12 flex h-full gap-6">
        <div className="flex min-w-[494px] flex-col justify-between">
          {deviceData.hasCharger && <ChargersStateOverview />}
          <div>
            {(deviceData.hasGridMeter || deviceData.hasGateway) && (
              <Typography variant="sectionTitle" className="pb-4 capitalize">
                {t_generic('literals.device_other')}
              </Typography>
            )}
            <div className="flex gap-6">
              {deviceData.hasGridMeter && <GridMeterCard />}
              {deviceData.hasGateway && <GatewayCard />}
            </div>
          </div>
        </div>
        <SiteOverview />
      </div>
      {deviceData.hasCharger && <ChargersOverview />}
    </>
  );
};
