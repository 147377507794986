import { ReactNode, useEffect } from 'react';

import { usePortalTranslation } from '@hooks/use-portal-translation';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

import { useAlert } from '@destination/components';

export const AxiosInterceptor = ({ children }: { children: ReactNode }) => {
  const navigate = useNavigate();
  const { notifyError } = useAlert();
  const { t } = usePortalTranslation('errors');

  const unauthorizedMessage = t('unauthorized_page', { titleize: true });
  const forbiddenMessage = t('forbidden_action', { titleize: true });

  useEffect(() => {
    const interceptor = axios.interceptors.response.use(
      response => response,
      error => {
        if (error.response?.status === 401) {
          navigate('/unauthorized');
        }
        if (error.response?.status === 403) {
          if (error.config.method === 'get') {
            notifyError({
              header: unauthorizedMessage,
              isUnique: true
            });
            navigate('/'); // navigate to homepage
          } else {
            notifyError({
              header: forbiddenMessage
            });
          }
        }
        return Promise.reject(error);
      }
    );

    return () => axios.interceptors.response.eject(interceptor);
  }, [unauthorizedMessage, forbiddenMessage, navigate, notifyError]);

  return children;
};
