import { useEffect, useState } from 'react';

import { usePortalTranslation } from '@hooks/use-portal-translation';

import { Input, Select, Typography } from '@destination/components';

import { DeviceModelParameters, OverridableEnum } from '@omnis-pulse-types';

export interface ParameterInputsProps {
  parameters: DeviceModelParameters[];
  onParameterChange: (
    updatedParameters: { name: string; value: string }[]
  ) => void;
  setIsValidForm: (isValid: boolean) => void;
  isDisabled: boolean;
}

export const ParameterInputs = ({
  parameters,
  onParameterChange,
  setIsValidForm,
  isDisabled
}: ParameterInputsProps) => {
  const [localParameters, setLocalParameters] = useState(
    parameters.map(param => ({ name: param.name, value: param.defaultValue }))
  );

  const { t } = usePortalTranslation();

  const handleParameterChange = (key: string, value: string) => {
    const updatedParameters = localParameters.map(param =>
      param.name === key ? { ...param, value } : param
    );
    setLocalParameters(updatedParameters);
    onParameterChange(updatedParameters);
  };

  useEffect(() => {
    const isValid = parameters.every(param => {
      const isRequired = param.overridable === OverridableEnum.MANDATORY;
      const value = localParameters.find(
        local => local.name === param.name
      )?.value;
      return !isRequired || (isRequired && value && value.trim() !== '');
    });

    setIsValidForm(isValid);
  }, [localParameters, parameters, setIsValidForm]);

  const renderInputs = (params: DeviceModelParameters[]) =>
    params.map(param => {
      const localValue =
        localParameters.find(localParam => localParam.name === param.name)
          ?.value ?? param.defaultValue;
      const isRequired = param.overridable === OverridableEnum.MANDATORY;
      if (param.possibleValues) {
        return (
          <div key={param.name}>
            <Typography>
              {t(`literals.${param.name}`, { titleize: true })}
              {isRequired ? '*' : ''}
            </Typography>
            <div className="w-full max-w-[342px]">
              <Select
                options={param.possibleValues.map(value => ({
                  label: value,
                  value
                }))}
                selected={
                  localValue ? { label: localValue, value: localValue } : null
                }
                onChange={option =>
                  handleParameterChange(
                    param.name,
                    option?.value.toString() || ''
                  )
                }
                suffixText={param.unit ?? ''}
                disabled={
                  isDisabled ||
                  param.overridable === OverridableEnum.NOT_ALLOWED
                }
              />
            </div>
          </div>
        );
      }
      return (
        <div key={param.name}>
          <Typography>
            {t(`literals.${param.name}`, { titleize: true })}
            {isRequired ? '*' : ''}
          </Typography>
          <div className="w-full max-w-[342px]">
            <Input
              id={param.name}
              value={localValue}
              type={param.type === 'string' ? 'text' : 'number'}
              onChange={e => handleParameterChange(param.name, e.target.value)}
              suffixText={param.unit ?? ''}
              disabled={
                isDisabled || param.overridable === OverridableEnum.NOT_ALLOWED
              }
            />
          </div>
        </div>
      );
    });

  return (
    <div className="[&>div:nth-child(odd)]:bg-[#FAFAFA] [&>div]:flex [&>div]:flex-col [&>div]:justify-between [&>div]:gap-y-2 [&>div]:rounded-[4px] [&>div]:py-2 [&>div]:pl-3 [&>div]:pr-[18px] [&>div]:md:flex-row [&>div]:md:items-center">
      {renderInputs(parameters)}
    </div>
  );
};
