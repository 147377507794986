import { useState } from 'react';

import { usePortalTranslation } from '@hooks/use-portal-translation';

import CloseIcon from '@icons/error_16.svg';
import WarningIcon from '@icons/warning_16.svg';

import { IconButton, Typography } from '@destination/components';

export const UnsupportedViewportDisclaimer = () => {
  const { t } = usePortalTranslation('errors');

  const [isDismissed, setIsDismissed] = useState(false);

  if (isDismissed) {
    return null;
  }

  return (
    <div
      data-testid="unsupported-viewport-disclaimer"
      className="w-100 sticky -mx-8 -mt-2 mb-3 border border-b-warning bg-[#FFF7E9] p-3 text-center small-screen:hidden"
    >
      <IconButton
        onClick={() => setIsDismissed(true)}
        className="absolute right-0 top-0"
        data-testid="dismiss-disclaimer"
      >
        <CloseIcon className="absolute right-3 top-3" />
      </IconButton>
      <Typography variant="smallTitle" className="mb-2 flex justify-center">
        <WarningIcon className="me-2 [&>circle]:fill-warning [&>circle]:stroke-warning" />
        {t('unsupported_viewport')}
      </Typography>
      <Typography className="smallText">
        {t('service_portal_not_supported_on_mobile')}
      </Typography>
    </div>
  );
};
