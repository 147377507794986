import { usePortalTranslation } from '@hooks/use-portal-translation';

import { CreateSiteForm } from '@components/sites/CreateSiteForm';

import { Typography } from '@destination/components';

export const Create = () => {
  const { t } = usePortalTranslation();

  return (
    <>
      <Typography variant="pageTitle" className="mb-6">
        {t('buttons.create', {
          titleize: true,
          entity: t('literals.site')
        })}
      </Typography>
      <CreateSiteForm />
    </>
  );
};
