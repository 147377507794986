import { useEffect } from 'react';

import { usePortalTranslation } from '@hooks/use-portal-translation';
import { useNavigate, useParams } from 'react-router-dom';

import { ChargerDetails } from '@components/charger/ChargerDetails';
import { TabLabel } from '@components/TabLabel';
import { useSiteDevicesContext } from '@context/SiteDevicesContext';
import ChargerIcon from '@icons/charger_96.svg';

import { NothingConfigured, Typography } from '@destination/components';

export const Chargers = () => {
  const { chargerId } = useParams();
  const navigate = useNavigate();

  const { t, t_generic } = usePortalTranslation('chargers');
  const { deviceData } = useSiteDevicesContext();

  useEffect(() => {
    if (!chargerId && deviceData.chargers.length) {
      navigate(`${deviceData.chargers[0].id}`);
    }
  }, [deviceData.chargers, chargerId, navigate]);

  if (deviceData.chargers.length === 0) {
    return (
      <NothingConfigured
        title={t_generic('labels.no_configured_other', {
          entity: t_generic('literals.charger_other')
        })}
        className="my-auto"
        icon={<ChargerIcon />}
      >
        <Typography
          className="tw-max-w-[263px] tw-text-center"
          variant="description"
        >
          {t('errors.no_configured_message')}
        </Typography>
      </NothingConfigured>
    );
  }

  const chargerName =
    deviceData.chargers.find(charger => charger.id === chargerId)?.name ??
    t_generic('literals.charger', { titleize: true });

  return (
    <>
      <TabLabel label={chargerName} />
      <ChargerDetails chargers={deviceData.chargers} />
    </>
  );
};
