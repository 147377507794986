import { useState } from 'react';

import { usePortalTranslation } from '@hooks/use-portal-translation';

import { EvseSessionDrawer } from '@components/charger/EvseSessionDrawer';
import { InsightsProvider } from '@context/InsightsContext';
import ArrowIcon from '@icons/arrow_16.svg';
import { formatDurationInHoursAndMinutes } from '@utils/date-utils';

import { IconButton, Tooltip, Typography } from '@destination/components';

import { Charger, ChargerActiveSession, EvseStatus } from '@omnis-pulse-types';

export interface IEvseActiveSessionDurationCell {
  charger: Charger;
  evse: EvseStatus;
  session: ChargerActiveSession;
}

export const EvseActiveSessionDurationCell = ({
  charger,
  evse,
  session
}: IEvseActiveSessionDurationCell) => {
  const { t } = usePortalTranslation();
  const [isActiveSessionDrawerOpen, setIsActiveSessionDrawerOpen] =
    useState(false);

  const handleClick = (event: React.MouseEvent | React.KeyboardEvent) => {
    event.stopPropagation();
    setIsActiveSessionDrawerOpen(true);
  };

  const tooltipMessage = t('buttons.view', {
    titleize: true,
    entity: t('labels.entity_session', {
      entity: t('literals.charging')
    })
  });

  return (
    <InsightsProvider>
      <div className="flex max-h-[15px] items-center justify-between">
        <Typography className="text-nowrap" variant="smallText">
          {formatDurationInHoursAndMinutes(
            Date.now(),
            new Date(session.startTime).getTime()
          )}
        </Typography>
        <Tooltip message={tooltipMessage}>
          <IconButton
            data-testid={`${session.id}-icon-button`}
            className="flex h-5 w-5 items-center justify-center rounded-full hover:bg-black/[.08] active:bg-black/[.16]"
            onClick={handleClick}
            onKeyDown={event => {
              if (event.key === 'Enter' || event.key === ' ') {
                handleClick(event);
              }
            }}
            tabIndex={0} // Ensure the button is focusable
          >
            <ArrowIcon className="rotate-90" />
          </IconButton>
        </Tooltip>
      </div>
      {isActiveSessionDrawerOpen && (
        <EvseSessionDrawer
          isOpen={isActiveSessionDrawerOpen}
          title={`${charger.name}-EVSE ${evse.id}`}
          onClose={() => setIsActiveSessionDrawerOpen(false)}
          session={session}
          chargerId={charger.id}
          evse={evse}
        />
      )}
    </InsightsProvider>
  );
};
