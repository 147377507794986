import { usePortalTranslation } from '@hooks/use-portal-translation';

import { DetailLine } from '@components/DetailsLine';

import { Card, Typography } from '@destination/components';

import { Gateway } from '@omnis-pulse-types';

export const GatewayProperties = ({ gateway }: { gateway: Gateway }) => {
  const { t } = usePortalTranslation();

  const firmwareLabel = t('labels.entity_version', {
    entity: t('literals.firmware'),
    titleize: true
  });

  return (
    <div className="h-min min-w-96 flex-1">
      <Card className="flex flex-col gap-4 !rounded-md">
        <DetailLine
          label={t('literals.serial_number', { titleize: true })}
          data-testid="terra-gateway-pro-settings-line-serial-number"
        >
          <Typography variant="description" className="font-bold">
            {gateway.serialNumber}
          </Typography>
        </DetailLine>
        <DetailLine
          label={firmwareLabel}
          data-testid="terra-gateway-pro-settings-line-firmware-version"
        >
          <Typography variant="description" className="font-bold">
            {gateway.firmwareVersion}
          </Typography>
        </DetailLine>
      </Card>
    </div>
  );
};
