import { usePortalTranslation } from '@hooks/use-portal-translation';

import { IOption, Select, Typography } from '@destination/components';

export interface IGridMeterManufacturerSelector {
  isDisabled: boolean;
  isLoading: boolean;
  isError: boolean;
  options: IOption[];
  selected: IOption | null;
  onChange: (option: IOption) => void;
  onRefresh: () => void;
}

export const GridMeterManufacturerSelector = ({
  isDisabled,
  isLoading,
  isError,
  options,
  selected,
  onChange,
  onRefresh
}: IGridMeterManufacturerSelector) => {
  const { t, t_generic } = usePortalTranslation('gridMeters');
  const { t: t_errors } = usePortalTranslation('errors');

  const label = t_generic('literals.manufacturer', { titleize: true });
  const placeholder = t('placeholders.choose_manufacturer');
  const searchPlaceholder = t('placeholders.search_manufacturer');

  return (
    <div>
      <Typography>{label}</Typography>
      <div className="w-full max-w-[342px]">
        <Select
          disabled={isDisabled}
          isLoading={isLoading}
          isLoadingError={isError}
          loadingErrorMessage={t_errors('data_could_not_load')}
          loadingErrorButtonLabel={t_errors('buttons.refresh', {
            titleize: true
          })}
          enableSearch
          searchPlaceholder={searchPlaceholder}
          placeholder={placeholder}
          options={options}
          selected={selected}
          onChange={onChange}
          onRefresh={onRefresh}
        />
      </div>
    </div>
  );
};
