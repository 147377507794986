import useSWR from 'swr';

import { fetcher } from '@utils/swr-utils';

import { ModelUsageReport } from '@omnis-pulse-types';

const fetchReportsForYears = async (
  years: number[]
): Promise<{ [key: number]: ModelUsageReport[] }> => {
  const responses = await Promise.all(
    years.map(year =>
      fetcher(`/api/reports?year=${year}`).then(res => ({ year, data: res }))
    )
  );

  return responses.reduce(
    (acc, { year, data }) => {
      acc[year] = data;
      return acc;
    },
    {} as { [key: number]: ModelUsageReport[] }
  );
};

const useReports = (years: number[] = []) => {
  const key = years.length > 0 ? `reports-${years.join('-')}` : null;

  const { data, error, isLoading, mutate } = useSWR(key, () =>
    fetchReportsForYears(years)
  );

  return {
    data: data || {},
    isLoading,
    isError: Boolean(error),
    mutate
  };
};

export default useReports;
