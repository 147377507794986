import axios from 'axios';

import { msalInstance } from '@config/authConfig';
import { getAccessToken } from '@utils/msal-utils';

const apiClient = axios.create({
  baseURL: import.meta.env.VITE_APP_BACKEND_API_URL
});

msalInstance.initialize().then(() => {
  apiClient.interceptors.request.use(async requestConfig => {
    const accessToken = await getAccessToken();
    requestConfig.headers.Authorization = `Bearer ${accessToken}`;
    return requestConfig;
  });
});

export default apiClient;
