import { useMsal } from '@azure/msal-react';
import { usePortalTranslation } from '@hooks/use-portal-translation';
import { Link } from 'react-router-dom';

import SettingsIcon from '@icons/settings_16.svg';
import ShoppingCartIcon from '@icons/shopping_cart_16.svg';
import SignOutIcon from '@icons/sign_out_16.svg';

import {
  MobileNavigation as MobileNav,
  Sidebar
} from '@destination/components';

import { MobileNavigation, Navigation } from './Navigation';

interface MenuNavigation {
  isMobile: boolean;
}

export const FooterNavigation = ({ isMobile }: MenuNavigation) => {
  const { t } = usePortalTranslation();
  const { instance } = useMsal();

  const redirectUrl = import.meta.env.VITE_APP_OMNIS_CUSTOMER_PORTAL_URL;

  const items = [
    {
      id: 'settings',
      icon: <SettingsIcon />,
      label: t('literals.settings_other', { titleize: true }),
      url: '/settings'
    }
  ];

  const handleLogout = async () => {
    await instance.logout();
  };

  if (isMobile) {
    return (
      <>
        <MobileNavigation
          items={items.map(item => ({
            ...item,
            onClick: undefined
          }))}
        />
        <Link
          to={redirectUrl ?? ''}
          target="_blank"
          rel="noopener noreferrer"
          className="cursor-pointer whitespace-nowrap"
        >
          <MobileNav.MenuItem
            icon={<ShoppingCartIcon />}
            isActive={false}
            label={t('labels.customer_portal', { titleize: true })}
          />
        </Link>
        <button
          className="cursor-pointer whitespace-nowrap"
          onClick={handleLogout}
          data-testid="sign-out"
        >
          <MobileNav.MenuItem
            icon={<SignOutIcon />}
            isActive={false}
            label={t('literals.sign_out', { titleize: true })}
          />
        </button>
      </>
    );
  }

  return (
    <>
      <Navigation
        items={items.map(item => ({
          ...item,
          onClick: undefined
        }))}
      />
      <Link
        to={redirectUrl ?? ''}
        target="_blank"
        rel="noopener noreferrer"
        className="cursor-pointer whitespace-nowrap"
      >
        <Sidebar.MenuItem
          icon={<ShoppingCartIcon />}
          isActive={false}
          label={t('labels.customer_portal', { titleize: true })}
        />
      </Link>
      <button
        className="cursor-pointer whitespace-nowrap"
        onClick={handleLogout}
        data-testid="sign-out"
      >
        <Sidebar.MenuItem
          icon={<SignOutIcon />}
          isActive={false}
          label={t('literals.sign_out', { titleize: true })}
        />
      </button>
    </>
  );
};
