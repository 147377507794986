import { usePortalTranslation } from '@hooks/use-portal-translation';

import { SiteSettingsContent } from '@components/sites/site/settings/SiteSettingsContent';

import { Typography } from '@destination/components';

export const SiteSettings = () => {
  const { t } = usePortalTranslation();
  const title = t('labels.entity_settings', {
    entity: t('literals.site', { titleize: true })
  });
  return (
    <section>
      <Typography variant="sectionTitle" className="mb-4">
        {title}
      </Typography>
      <SiteSettingsContent />
    </section>
  );
};
