import { useState } from 'react';

import { usePortalTranslation } from '@hooks/use-portal-translation';

import { DetailLine } from '@components/DetailsLine';
import EditIcon from '@icons/edit_16.svg';

import { IconButton, Typography } from '@destination/components';

import { EditChargerNameDialog } from './edit-charger/EditChargerNameDialog';

interface IChargerNamePropertyRow {
  name: string;
}

export const ChargerNamePropertyRow = ({ name }: IChargerNamePropertyRow) => {
  const [showDialog, setShowDialog] = useState(false);

  const { t } = usePortalTranslation();

  const label = t('literals.name', { titleize: true });

  return (
    <>
      <DetailLine label={label}>
        <div className="flex items-center gap-1">
          <Typography className="break-all font-bold">{name}</Typography>
          <div className="-mt-[14px] max-h-[18px]">
            <IconButton
              data-testid="edit-icon-button"
              onClick={() => setShowDialog(true)}
            >
              <EditIcon />
            </IconButton>
          </div>
        </div>
      </DetailLine>
      <EditChargerNameDialog
        showDialog={showDialog}
        name={name}
        onClose={() => setShowDialog(false)}
      />
    </>
  );
};
