import useSWR from 'swr';

import { GRID_METERS_BY_SITE_ID_PATH } from '@api/paths';

import { GridMeter } from '@omnis-pulse-types';

export default function useSiteGridMeter(siteId?: string) {
  const { data, isLoading, error, mutate } = useSWR<GridMeter[]>(
    siteId ? GRID_METERS_BY_SITE_ID_PATH(siteId) : null
  );

  return {
    gridMeters: data,
    isLoading,
    isError: Boolean(error),
    mutate
  };
}
