import { usePortalTranslation } from '@hooks/use-portal-translation';

import useGatewayChargers from '@api/gateways/use-gateway-chargers';

import {
  DataError,
  Grid,
  TableLoading,
  Typography
} from '@destination/components';

import { ConnectedChargersTableRow } from './ConnectedChargersTableRow';

export interface ITgpConnectedChargersTable {
  gatewayId: string;
}

export const ConnectedChargersTable = ({
  gatewayId
}: ITgpConnectedChargersTable) => {
  const { t, t_generic } = usePortalTranslation('gateways');

  const { chargers, isLoading, isError, mutate } =
    useGatewayChargers(gatewayId);

  if (isError) {
    return <DataError onRefresh={mutate} />;
  }

  const chargerNameLabel = t_generic('labels.entity_name', {
    entity: t_generic('literals.charger')
  });
  const tgpConnectionLabel = t('labels.connection_with_entity', {
    entity: 'tgp'
  });
  const lastConnectionLabel = t_generic('labels.last_entity', {
    entity: t_generic('literals.connection')
  });

  return (
    <>
      <Typography variant="sectionTitle" className="mb-4">
        {t('labels.connected_chargers', { titleize: true })}
      </Typography>
      <Grid
        data-testid="connected-chargers-table"
        className="grid-cols-[minmax(175px,auto)_minmax(175px,auto)_minmax(175px,auto)_1fr]"
      >
        <Grid.HeadCell>{chargerNameLabel}</Grid.HeadCell>
        <Grid.HeadCell>{t_generic('literals.serial_number')}</Grid.HeadCell>
        <Grid.HeadCell>{tgpConnectionLabel}</Grid.HeadCell>
        <Grid.HeadCell>{lastConnectionLabel}</Grid.HeadCell>
        {isLoading && <TableLoading className="col-span-4 mt-3" />}
        {chargers?.length === 0 && (
          <Typography className="col-span-4 mt-4 text-center">
            {t('labels.no_chargers', { titleize: true })}
          </Typography>
        )}
        {chargers?.map((charger, index) => (
          <ConnectedChargersTableRow
            key={charger.id}
            index={index}
            charger={charger}
          />
        ))}
      </Grid>
    </>
  );
};
