import { AnalyticsRecord } from '@omnis-pulse-types';

export const months = [
  'jan',
  'feb',
  'mar',
  'apr',
  'may',
  'jun',
  'jul',
  'aug',
  'sep',
  'oct',
  'nov',
  'dec'
];

export function getLastTwoMonths(currentMonth: number): string[] {
  const previousMonth = currentMonth === 0 ? 11 : currentMonth - 1;
  const secondPreviousMonth = previousMonth === 0 ? 11 : previousMonth - 1;

  return [months[secondPreviousMonth], months[previousMonth]];
}

export function transformDateToMonthAbbreviation(
  data: AnalyticsRecord[]
): AnalyticsRecord[] {
  return data.map(record => ({
    ...record,
    date: record.date ? months[new Date(record.date).getMonth()] : undefined
  }));
}
