import useSWR from 'swr';

import { CHARGERS_BY_SITE_ID_PATH } from '@api/paths';

import { SiteChargersStatusTelemetry } from '@omnis-pulse-types';

const useSiteEvsesStatusTelemetry = (
  siteId?: string,
  from?: string,
  to?: string
) => {
  const url = siteId
    ? `${CHARGERS_BY_SITE_ID_PATH(siteId)}/telemetry/status?from=${from}&to=${to}`
    : null;

  const { data, isLoading, error, mutate } =
    useSWR<SiteChargersStatusTelemetry[]>(url);

  return { data: data, isLoading, isError: Boolean(error), mutate };
};

export default useSiteEvsesStatusTelemetry;
