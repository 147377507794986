import { useEffect } from 'react';

import { usePortalTranslation } from '@hooks/use-portal-translation';
import { useNavigate, useParams } from 'react-router-dom';

import { GatewayDetails } from '@components/gateways/GatewayDetails';
import { TabLabel } from '@components/TabLabel';
import { useSiteDevicesContext } from '@context/SiteDevicesContext';
import GatewayIcon from '@icons/gateway_48.svg';

import { NothingConfigured, Typography } from '@destination/components';

export const Gateways = () => {
  const { t } = usePortalTranslation();
  const { t: t_gateways } = usePortalTranslation('gateways');

  const { gatewayId } = useParams();
  const navigate = useNavigate();

  const { deviceData } = useSiteDevicesContext();

  useEffect(() => {
    if (!gatewayId && deviceData.gateways?.length) {
      navigate(`${deviceData.gateways[0].id}`);
    }
  }, [deviceData.gateways, gatewayId, navigate]);

  if (deviceData.gateways.length === 0) {
    return (
      <NothingConfigured
        title={t('labels.no_configured_other', {
          entity: t('literals.gateway')
        })}
        className="my-auto"
        icon={<GatewayIcon />}
      >
        <Typography
          className="tw-max-w-[263px] tw-text-center"
          variant="description"
        >
          {t_gateways('labels.gateway_configured_status')}
        </Typography>
      </NothingConfigured>
    );
  }

  const gatewayName =
    deviceData.gateways.find(gateway => gateway.id === gatewayId)?.name ??
    'Gateway';

  return (
    <>
      <TabLabel label={gatewayName} />
      <GatewayDetails gateways={deviceData.gateways} />
    </>
  );
};
