import { Dispatch, SetStateAction, useEffect } from 'react';

import { usePortalTranslation } from '@hooks/use-portal-translation';
import { useParams } from 'react-router-dom';

import useProductTypeDeviceModels from '@api/sites/devices/use-product-type-device-models';

import {
  Card,
  Disclaimer,
  LoadingSkeleton,
  RadioButton,
  Typography
} from '@destination/components';

import { DeviceModelParameters, ProductType } from '@omnis-pulse-types';

export interface IGridMeterProtocolCard {
  isDisabled: boolean;
  productTypeIdentifier: ProductType['identity'];
  setCommunicationParameters: Dispatch<SetStateAction<DeviceModelParameters[]>>;
  setExtraParameters: Dispatch<SetStateAction<DeviceModelParameters[]>>;
}

export const GridMeterProtocolCard = ({
  isDisabled,
  productTypeIdentifier,
  setCommunicationParameters,
  setExtraParameters
}: IGridMeterProtocolCard) => {
  const { t, t_generic } = usePortalTranslation('gridMeters');
  const { id } = useParams();

  const { deviceModels, isLoading, isError } = useProductTypeDeviceModels(
    id,
    productTypeIdentifier
  );

  const tcpVariant =
    !isLoading && !isError
      ? deviceModels?.variants?.find(variant => variant.name === 'tcp')
      : undefined;

  const showError =
    !isLoading && !isError && !tcpVariant && !!deviceModels?.variants?.length;

  useEffect(() => {
    if (!isLoading && !isError) {
      setCommunicationParameters(tcpVariant?.parameters || []);
      if (deviceModels?.extraParameters) {
        setExtraParameters(deviceModels?.extraParameters);
      }
    }
  }, [
    tcpVariant,
    isLoading,
    isError,
    setCommunicationParameters,
    setExtraParameters,
    deviceModels?.extraParameters
  ]);

  const renderContent = () => {
    if (isLoading) {
      return <LoadingSkeleton height={50} />;
    }

    if (isError || !deviceModels) {
      return (
        <Disclaimer
          variant="warning"
          message={t('protocols.failed_to_load_message')}
        />
      );
    }

    return showError ? (
      <Disclaimer variant="warning" message={t('protocols.warning_message')} />
    ) : (
      <div className="bg-[#FAFAFA] px-3 py-4">
        <RadioButton
          label={t('protocols.tgp')}
          checked
          disabled={isDisabled}
          onChange={() => undefined}
        />
      </div>
    );
  };

  return (
    <Card data-testid="grid-meter-protocol-card" className="max-w-[620px] py-6">
      <Typography variant="smallTitle" className="mb-4 ml-3">
        {t_generic('literals.protocol', { titleize: true })}
      </Typography>
      <div className="mb-[11px]">{renderContent()}</div>
    </Card>
  );
};
