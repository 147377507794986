import useSWR from 'swr';

import { SITE_CHARGING_STRATEGY_PATH } from '@api/paths';

import type { SiteChargingStrategy } from '@omnis-pulse-types';

export default function useSiteChargingStrategy(siteId?: string) {
  const { data, isLoading, error, mutate } = useSWR<SiteChargingStrategy>(
    siteId ? SITE_CHARGING_STRATEGY_PATH(siteId) : null
  );

  return {
    data,
    isLoading,
    isError: Boolean(error),
    mutate
  };
}
