import apiClient from '@api/apiClient';

export const fetcher = async (url: string) => {
  const { data } = await apiClient.get(url);
  return data;
};

export const modifier = async (
  url: string,
  method: 'POST' | 'PATCH' | 'PUT' | 'DELETE',
  payload: object = {},
  usesFormData = false
) => {
  const headers: { [key: string]: string } = {};
  let body;

  if (usesFormData) {
    headers['Content-Type'] = 'multipart/form-data';
    body = new FormData();
    for (const [attribute, value] of Object.entries(payload)) {
      if (Array.isArray(value)) {
        for (const item of value) {
          body.append(attribute, item);
        }
      } else {
        body.append(attribute, value);
      }
    }
  } else {
    body = payload;
  }

  try {
    switch (method) {
      case 'POST': {
        const { data } = await apiClient.post(url, body, {
          method,
          headers
        });
        return { error: undefined, data };
      }
      case 'PUT': {
        const { data } = await apiClient.put(url, body, {
          method,
          headers
        });
        return { error: undefined, data };
      }
      case 'PATCH': {
        const { data } = await apiClient.patch(url, body, {
          method,
          headers
        });
        return { error: undefined, data };
      }
      case 'DELETE': {
        const { data } = await apiClient.delete(url, { method, headers });
        return { error: undefined, data };
      }
    }
  } catch (error) {
    return { error, data: undefined };
  }
};
