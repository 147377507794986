import { usePortalTranslation } from '@hooks/use-portal-translation';
import { Link, useOutletContext, useParams } from 'react-router-dom';

import { ChargerEventLog } from '@components/chargers/ChargerEventLogs';
import { ChargerInformation } from '@components/chargers/ChargerInformation';
import { ChargerSessionsContainer } from '@components/chargers/ChargerSessionsContainer';
import NewTabIcon from '@icons/new_tab_16.svg';
import { getIsChargerConnected } from '@utils/charger-utils';

import { Typography } from '@destination/components';

import { ChargerDetails } from '@omnis-pulse-types';

export const Charger = () => {
  const { id: siteId } = useParams();

  const charger = useOutletContext<ChargerDetails>();

  const { t } = usePortalTranslation();

  const sectionTitle = t('labels.entity_information', {
    entity: t('literals.charger'),
    titleize: true
  });
  const smallTitle = t('labels.entity_properties', {
    entity: t('literals.charger'),
    titleize: true
  });
  const redirectUrl =
    charger.manufacturer === 'ABB'
      ? `https://chargers.evcharging.abb.com/goto?serial=${charger.serialNumber}`
      : undefined;

  return (
    <>
      <ChargerSessionsContainer
        siteId={siteId}
        chargerId={charger.id}
        isChargerConnected={getIsChargerConnected(charger)}
      />
      <div className="mb-4 flex max-h-[18px] items-center gap-2">
        <Typography variant="sectionTitle">{sectionTitle}</Typography>
        {redirectUrl && (
          <Link
            data-testid="redirect-eve-url"
            to={redirectUrl ?? ''}
            target="_blank"
            rel="noopener noreferrer"
            className="flex gap-1 text-[#3366ff]"
          >
            <Typography variant="smallTitle">EVE</Typography>
            <NewTabIcon />
          </Link>
        )}
      </div>
      <Typography className="mb-4" variant="smallTitle">
        {smallTitle}
      </Typography>
      <div className="mb-12 flex gap-[30px]">
        <ChargerInformation charger={charger} />
        <div className="w-full" />
      </div>
      <ChargerEventLog chargerId={charger.id} />
    </>
  );
};
