import { SITE_PEAK_CAPACITY_PATH } from '@api/paths';
import { modifier } from '@utils/swr-utils';

import { UpdateSitePeakCapacity } from '@omnis-pulse-types';

export default async function updateSiteEnergyManagementPeakCapacity(
  siteId: string,
  payload: UpdateSitePeakCapacity
) {
  const url = SITE_PEAK_CAPACITY_PATH(siteId);
  return await modifier(url, 'PATCH', payload);
}
